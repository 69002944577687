import { useRef, useState } from "react";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const ModalDigitalizacionOCP = ({ open, onClose, onConfirm, setFileDigitalizacion }) => {
    /*  estilos modal */
    const dialogTitleStyle = { textAlign: 'left', fontWeight: 'bold', fontSize: '22px', color: '#000000B3' };
    const dialogActionsStyle = { justifyContent: 'right' };
    const buttonStyle = { textTransform: 'none' };


    const { showSnackbar } = useToastAlert();
    const [formDataTemp, setFormDataTemp] = useState({
        acuseFile: "",
        estatusAcuse: "",
        fechaEntregaOficio: "",
        acuseComentario: ""
    });
    const fileInputRefAcuse = useRef(null);

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        console.log(file.size);

        if (!file) return;
        if (file.size > 50 * 1024 * 1024) {
            showSnackbar('El archivo debe ser menor a 50 MB', 'error');
            return;
        }
        if (file.type !== "application/pdf") {
            showSnackbar("Solo se permiten archivos PDF.", "error");
            return;
        }

        setFormDataTemp((prevState) => ({
            ...prevState,
            acuseFile: file
        }));

    };

    const handlePreviewFile = (event) => {
        const file = formDataTemp.acuseFile;
        let blob = new Blob([file], { type: 'application/pdf' });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob)
        link.target = "acuse.pdf"
        link.click()
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    width: '700px',
                    maxWidth: '90vw',
                    height: 'auto',
                    padding: '5px',
                    position: 'fixed'
                },
            }}
            disableEscapeKeyDown
        >

            <DialogTitle style={dialogTitleStyle}>Digitalización</DialogTitle>
            <DialogContent>
                <Grid container spacing={2} rowSpacing={2} mt={1}>
                    <Grid item xs={12}>
                        <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                            <Grid item xs={12} sm={6}>
                                <Button
                                    component="label"
                                    variant="contained"
                                    startIcon={<CloudUploadIcon />}
                                    fullWidth
                                >
                                    Cargar acuse PDF
                                    <input
                                        accept="application/pdf"
                                        type="file"
                                        hidden
                                        onChange={handleFileUpload}
                                        ref={fileInputRefAcuse}
                                    />
                                </Button>
                            </Grid>

                            <Grid item xs={0} sm={2}></Grid>

                            {formDataTemp.acuseFile && (
                                <Grid item xs={12} sm={4}>
                                    <Card sx={{ maxWidth: 130, position: 'relative', margin: '0 auto' }}>
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                position: 'absolute',
                                                top: 4,
                                                right: 4,
                                                cursor: 'pointer',
                                                fontWeight: 'bold',
                                                color: 'red',
                                            }}
                                            onClick={() => {
                                                setFormDataTemp((prevState) => ({
                                                    ...prevState,
                                                    acuseFile: ""
                                                }));

                                                if (fileInputRefAcuse.current) {
                                                    fileInputRefAcuse.current.value = null;
                                                }
                                            }

                                            }
                                        >
                                            X
                                        </Typography>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: 80,
                                            }}
                                        >
                                            <PictureAsPdfIcon sx={{ fontSize: 50, color: 'black' }} />
                                        </Box>
                                        <CardContent sx={{ textAlign: 'center', padding: 1 }}>

                                            <Typography
                                                variant="body1"
                                                sx={{
                                                    color: "blue",
                                                    textDecoration: "underline",
                                                    cursor: "pointer",
                                                    "&:hover": {
                                                        textDecoration: "none",
                                                    },
                                                }}
                                                onClick={handlePreviewFile}
                                            >
                                                acuse.pdf
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )}

                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions style={dialogActionsStyle}>
                <Button
                    variant="outlined"
                    className="button-close"
                    onClick={onClose}
                    style={{ ...buttonStyle, borderColor: '#636569', color: '#fff' }}
                >
                    Cancelar
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        onConfirm(formDataTemp.acuseFile);
                        onClose();
                        setFormDataTemp({
                            acuseFile: "",
                            estatusAcuse: "",
                            fechaEntregaOficio: "",
                            acuseComentario: ""
                        })
                    }}
                    className="button-save"
                    style={{ ...buttonStyle, borderColor: '#8c92bc', color: '#fff' }}
                >
                    Digitalizar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ModalDigitalizacionOCP;