import { createBrowserRouter, redirect } from 'react-router-dom';
import Layout from '../../layouts/Layout';
import Juzgados from '../../pages/Juzgados/Juzgados';
import Materias from '../../pages/Materias/Materias';
import ListaValor from '../../pages/ListaValor/ListaValor';
import Home from '../../pages/Home/Home';
import Personas from '../../pages/Personas/Personas';
import Oficialias from '../../pages/Oficialias/Oficialias';
import Salas from '../../pages/Salas/Salas';
import Digitalizacion from '../../pages/Bandeja/AccionesRapidas/Digitalizacion/Digitalizacion'
import Bandeja from '../../pages/Bandeja/Bandeja';
import BandejaSalida from '../../pages/BandejaSalida/BandejaSalida';
import BandejaOficios from '../../pages/BandejaOficios/BandejaOficios';
import Institucion from '../../pages/Instituciones/Institucion'
import Bloque from '../../pages/Bloques/Bloque';
import BandejaAsignados from '../../pages/BandejaAsignados/BandejaAsignados';
import Asignado from '../../pages/Bandeja/Asignado/Asignado';
import DetalleExpediente from '../../pages/Bandeja/Asignado/Tabs/General/DetalleExpediente';
import RegistroOficios from '../../pages/Oficios/RegistroOficios';
import DigitalizacionOficios from '../../pages/Oficios/Digitalizacion/DigitalizacionOficios';
import AcuerdoNotificacion from '../../pages/Bandeja/Asignado/Tabs/Acuerdo/AcuerdoNotificacion';
import CrearAcuerdo from '../../pages/Bandeja/Asignado/Tabs/Acuerdo/CrearAcuerdoSentencia';
import FormDemandaPadre from '../../pages/Demanda/Form/FormDemandaPadre';
import BandejaEnvios from '../../pages/BandejaEnvios/BandejaEnvios';
import GeneradorEtiquetas from '../../pages/Etiquetas/GeneradorEtiquetas';
import BandejaDevueltos from '../../pages/BandejaDevueltos/BandejaDevueltos';

//Formularios
import ProtectedRoute from './ProtectedRoute';
import CreateJuzgado from '../../pages/Juzgados/CRUD/CreateJuzgado';
import EditJuzgado from '../../pages/Juzgados/CRUD/EditJuzgado';
import CreateListaValor from '../../pages/ListaValor/CRUD/CreateListaValor';
import EditListaValor from '../../pages/ListaValor/CRUD/EditListaValor';
import CreateOficialia from '../../pages/Oficialias/CRUD/CreateOficialia';
import EditOficialia from '../../pages/Oficialias/CRUD/EditOficialia';
import Sede from '../../pages/Sedes/Sede';
import CreateSede from '../../pages/Sedes/Form/CreateSede';
import EditSede from '../../pages/Sedes/Form/EditSede';
import Demanda from '../../pages/Demanda/Demanda';
import Promocion from '../../pages/Promocion/Promocion';
import EditPromocion from '../../pages/Promocion/Form/EditPromocion';
import FormDemanda from '../../pages/Demanda/Form/FormDemanda';
import FormDemandaFamiliar from '../../pages/Demanda/Form/FormFamiliar/FormDemandaFamiliar';
import EditPersona from '../../pages/Personas/CRUD/Formulario/EditPersona';
import CreateSala from '../../pages/Salas/CRUD/CreateSala';
import EditSala from '../../pages/Salas/CRUD/EditSala';
import EditFormDemanda from '../../pages/Demanda/Form/EditFormDemanda';
import ListaEstrado from '../../pages/Bandeja/ListaEstrados/ListaEstrado';
import CreateInstitucion from '../../pages/Instituciones/Form/CreateInstitucion';
import EditInstitucion from '../../pages/Instituciones/Form/EditInstitucion';
import CreateBloque from '../../pages/Bloques/Form/CreateBloque';
import EditBloque from '../../pages/Bloques/Form/EditBloque';
import FormularioExhortos from '../../pages/Exhorto/Form/FormExhorto';
import Carpeta from '../../pages/Carpeta/Carpeta';
import Apelacion from '../../pages/Apelacion/Apeleacion';
import Recepcion from '../../pages/Bandeja/Recepcion/Recepcion';
import RecepcionList from '../../pages/Bandeja/Recepcion/RecepcionList';
import Amparo from '../../pages/Amparo/Amparo';
import BandejaAudienciasGenerales from '../../pages/BandejaAudiencias/BandejaAudienciasGenerales';
import RegistrosAntiguos from '../../pages/RegistrosAntiguos/RegistrosAntiguos';
import AgregarParticipante from '../../pages/Bandeja/Asignado/TabsParticipante/AgregarParticipante';
import EditFormExhorto from '../../pages/Exhorto/Form/EditFormExhorto';
import FormularioExhortoSalida from '../../pages/Exhorto/ExhortoSalida/FormExhortoSalida';
import VistaAudienciaGeneral from '../../pages/BandejaAudiencias/VistaAudienciaGeneral';
import SentenciaPublica from '../../pages/Sentencia/Publica/SentenciaPublica';
import LibroGobierno from '../../pages/LibroGobierno/LibroGobierno';
import Notificacion from '../../pages/Notificacion/Notificacion';
import ReprogramarAudiencia from '../../pages/BandejaAudiencias/Form/ReprogramarAudiencia';
import Transferencia from '../../pages/Transferencia/Transferencia';
import NotificacionesAcuerdoTable from '../../pages/Bandeja/Grid/NotificacionesAcuerdoTable';
import FormularioAudiencia from '../../pages/BandejaAudiencias/Form/FormAudiencias';
import Calendarios from '../../pages/Calendarios/Calendarios';
import ArchivoJudicial from '../../pages/ArchivoJudicial/ArchivoJudicial';
import FormDemandaPenal from '../../pages/Demanda/Form/FormPenal/FormDemandaPenal';
import Concepto from '../../pages/Conceptos/Concepto';
import FormConcepto from '../../pages/Conceptos/Form/FormConcepto';
import EditConcepto from '../../pages/Conceptos/Form/EditConcepto';
import ReasignacionExpediente from '../../pages/Registro/ReasignacionExpedientes/ReasignacionExpediente';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: '/',
        loader: () => redirect('/home'), // Redirige de '/' a '/home' automáticamente
      },
      {
        path: '/home', // Ruta para Home
        element:
          <Home />, // Componente que manejará esta ruta


      },
      {
        path: '/api/core/juzgados', // Ruta para juzgados
        element: (
          <ProtectedRoute>
            <Juzgados />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/oficialias',
        element: (
          <ProtectedRoute>
            <Oficialias />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/recursos', // Ruta para recursos 
        element: <Home />,
      },
      {
        path: '/api/core/personas',
        element: (
          <ProtectedRoute>
            <Personas />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/personal/edit',
        element: (
          <ProtectedRoute>
            <EditPersona />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/materias',
        element: (
          <ProtectedRoute>
            <Materias />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/oficialias/crear_oficialia',
        element: (
          <ProtectedRoute>
            <CreateOficialia />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/oficialias/editar_oficialia', // La ruta para editar un juzgado
        element: (
          <ProtectedRoute>
            <EditOficialia />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/juzgados/crear_juzgado',
        element: (
          <ProtectedRoute>
            <CreateJuzgado />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/juzgados/editar_juzgado', // La ruta para editar un juzgado
        element: (
          <ProtectedRoute>
            <EditJuzgado />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/listavalor',
        element: (
          <ProtectedRoute>
            <ListaValor />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/listavalor/add',
        element: (
          <ProtectedRoute>
            <CreateListaValor />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/listavalor/edit',
        element: (
          <ProtectedRoute>
            <EditListaValor />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/sedes',
        element: (
          <ProtectedRoute>
            <Sede />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/sedes/add',
        element: (
          <ProtectedRoute>
            <CreateSede />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/sedes/edit',
        element: (
          <ProtectedRoute>
            <EditSede />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/workflow/demanda',
        element: (
          <ProtectedRoute>
            <Demanda />
          </ProtectedRoute>
        ),
      },

      {
        path: '/api/workflow/exhorto',
        element: (
          <ProtectedRoute>
            <FormularioExhortos />
          </ProtectedRoute>
        ),
      },

      {
        path: '/api/workflow/demanda/add',
        element: (
          <ProtectedRoute>
            <FormDemanda />
          </ProtectedRoute>
        ),
      },

      {
        path: '/api/workflow/demanda/edit',
        element: (
          <ProtectedRoute>
            <EditFormDemanda />
          </ProtectedRoute>
        ),
      },


      {
        path: '/api/workflow/demanda/familiar',
        element: (
          <ProtectedRoute>
            <FormDemandaFamiliar />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/workflow/promocion',
        element: (
          <ProtectedRoute>
            <Promocion />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/bandeja/entrada/correccion/promocion',
        element: (
          <ProtectedRoute>
            <EditPromocion />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/salas',
        element: (
          <ProtectedRoute>
            <Salas />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/salas/editar_salas',
        element: (
          <ProtectedRoute>
            <EditSala />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/salas/crear_salas',
        element: (
          <ProtectedRoute>
            <CreateSala />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/digitalizacion',
        element: (
          <ProtectedRoute>
            <Digitalizacion />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/bandeja/entrada/',
        element: (
          <ProtectedRoute>
            <Bandeja />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/salida/',
        element: (
          <ProtectedRoute>
            <BandejaSalida />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/oficios/',
        element: (
          <ProtectedRoute>
            <BandejaOficios />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/core/instituciones/',
        element: (
          <ProtectedRoute>
            <Institucion />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/core/instituciones/editar_institucion',
        element: (
          <ProtectedRoute>
            <EditInstitucion />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/instituciones/crear_institucion',
        element: (
          <ProtectedRoute>
            <CreateInstitucion />
          </ProtectedRoute>
        ),
      },
      {

        path: '/api/core/bloques',
        element: (
          <ProtectedRoute>
            <Bloque />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/core/bloques/add',
        element: (
          <ProtectedRoute>
            <CreateBloque />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/core/bloques/edit',
        element: (
          <ProtectedRoute>
            <EditBloque />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/bandeja/historial',
        element: (
          <ProtectedRoute>
            <Carpeta />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/workflow/apelacion',
        element: (
          <ProtectedRoute>
            <Apelacion />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/bandeja/recepcion',
        element: (
          <ProtectedRoute>
            <RecepcionList />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/recepcion/anexos',
        element: (
          <ProtectedRoute>
            <Recepcion />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/asignados/',
        element: (
          <ProtectedRoute>
            <BandejaAsignados />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/expediente/',
        element: (
          <ProtectedRoute>
            <Asignado />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/expediente/detalle',
        element: (
          <ProtectedRoute>
            <DetalleExpediente />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/agregar-participante',
        element: (
          <ProtectedRoute>
            <AgregarParticipante />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/workflow/oficio/',
        element: (
          <ProtectedRoute>
            <RegistroOficios />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/documentoContenido',
        element: (
          <ProtectedRoute>
            <DigitalizacionOficios />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/expediente/agregarAcuerdo',
        element: (
          <ProtectedRoute>
            <CrearAcuerdo />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/acuerdo/acuerdoNotificacion',
        element: (
          <ProtectedRoute>
            <AcuerdoNotificacion />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/amparo',
        element: (
          <ProtectedRoute>
            <Amparo />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/bandeja/audiencias',
        element: (
          <ProtectedRoute>
            <BandejaAudienciasGenerales />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/registro',
        element: (
          <ProtectedRoute>
            <RegistrosAntiguos />
          </ProtectedRoute>
        )
      },

      {
        path: '/api/workflow/exhorto/edit',
        element: (
          <ProtectedRoute>
            <EditFormExhorto />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/workflow/exhorto/salida',
        element: (
          <ProtectedRoute>
            <FormularioExhortoSalida/>
          </ProtectedRoute>
        ),
      },

      {
        path: '/api/bandeja/audiencias/crearAudiencias',
        element: (
          <ProtectedRoute>
            <FormularioAudiencia />
          </ProtectedRoute>
        ),
      },
      {
        path: '/api/bandeja/audiencias/vista',
        element: (
          <ProtectedRoute>
            <VistaAudienciaGeneral />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/librogobierno',
        element: (
          <ProtectedRoute>
            <LibroGobierno />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/archivoJudicial',
        element: (
          <ProtectedRoute>
            <ArchivoJudicial />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/notificaciones',
        element: (
          <ProtectedRoute>
            <Notificacion />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/listaestrado',
        element: (
          <ProtectedRoute>
            <ListaEstrado />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/sentencia/publica',
        element: (
          <ProtectedRoute>
            <SentenciaPublica />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/reprogramarAudiencias',
        element: (
          <ProtectedRoute>
            <ReprogramarAudiencia />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/transferencia',
        element: (
          <ProtectedRoute>
            <Transferencia />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/expediente/notificacionesAcuerdo',
        element: (
          <ProtectedRoute>
            <NotificacionesAcuerdoTable />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/core/calendarios',
        element: (
          <ProtectedRoute>
            <Calendarios />
          </ProtectedRoute>
        )
      },
      {

        path: '/api/core/conceptos',
        element: (
          <ProtectedRoute>
            <Concepto/>
          </ProtectedRoute>
        )
      },
      {

        path: '/api/core/conceptos/crear_conceptos',
        element: (
          <ProtectedRoute>
            <FormConcepto/>
          </ProtectedRoute>
        )
      },

      {

        path: '/api/core/conceptos/editar_concepto',
        element: (
          <ProtectedRoute>
            <EditConcepto/>
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/demanda/penal',
        element: (
          <ProtectedRoute>
            <FormDemandaPenal />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/create-demanda',
        element: (
          <ProtectedRoute>
            <FormDemandaPadre />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/reasignacionExpediente',
        element: (
          <ProtectedRoute>
            <ReasignacionExpediente />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/envios',
        element: (
          <ProtectedRoute>
            <BandejaEnvios />
          </ProtectedRoute>
        )
      },
      {
        path: '/api/workflow/generacionEtiquetas',
        element:(
          <ProtectedRoute>
            <GeneradorEtiquetas/>
          </ProtectedRoute>
        )
      },
      {
        path: '/api/bandeja/devueltos',
        element:(
          <ProtectedRoute>
            <BandejaDevueltos/>
          </ProtectedRoute>
        )
      }



    ],
  },
]);
export default router;