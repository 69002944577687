import React, { useState, useEffect, useContext } from 'react';
import { Modal as MuiModal, Box, Typography, Button, Select, MenuItem, IconButton, Divider, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchMotivosDevolucion, createMotivoDevolucion } from '../../pages/Bandeja/Recepcion/BandejaService';
import keycloakConf from "../../api/keycloakConf";
import { useToastAlertValidation } from '../Alert/ToastAlertValidation/ToastAlertValidation';
import { fetchDevolverABandeja } from '../../pages/BandejaDevueltos/BandejaDevueltosService';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
    p: 2,
    maxWidth: '700px',
    width: '100%',
};

const ReturnBandejasModal = ({ isOpen, handleClose, expediente, handleSave, handleRefresh }) => {
    const [motivoPersonalizado, setMotivoPersonalizado] = useState('');
    const [motivo, setMotivo] = useState('');
    const [destino, setDestino] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { getToken } = useContext(keycloakConf);
    const { showSnackbar } = useToastAlertValidation();
    const destinos = [
    { id: 1, nombre: "Bandeja de entrada", estado:"CAPTURA" },    
    { id: 2, nombre: "Digitalización", estado: "CAPTURA" },
    { id: 3, nombre: "Bandeja de salida", estado:"SALIDA" }]


    const documentoId = expediente?.documentoId;
    const carpetaId = expediente?.carpetaId;
    const expedienteNombre = expediente?.expediente || 'N/A';

    const handleSaveSuccess = () => {
        showSnackbar(`Expediente ${expedienteNombre} devuelto correctamente`, 'success', '¡Excelente!');
        handleClose();
    };

    const handleConfirm = async () => {
        setIsLoading(true);
        setError(null);
        try {
            const token = getToken();
            const motivoFinal = motivoPersonalizado;
            const estado = destinos.find(d => d.nombre === destino).estado
          
            const data = {
                carpetaId: carpetaId,
                documentoId: documentoId,
                motivoDevolucion: motivoFinal,
                estado: estado
            }

         
            await fetchDevolverABandeja(token, data);

            handleSave(motivoFinal);
            handleSaveSuccess();
            handleRefresh();
        } catch (error) {
            console.error('Error al crear el motivo de devolución:', error);
            setError('Hubo un error al guardar el motivo de devolución. Por favor, intente nuevamente.');
            setMotivo('');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <MuiModal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', color: '#333' }}>
                        Devolver a bandeja
                    </Typography>
                    <IconButton onClick={() => {
                        handleClose();
                        setMotivo('');
                    }}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Divider sx={{ my: 1 }} />
                <Typography sx={{ mt: 2 }}>
                    Seleccione el destino:
                </Typography>

                <Select
                    value={destino}
                    onChange={(e) => setDestino(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                >
                    {destinos.map(destino => (
                        <MenuItem key={destino.id} value={destino.nombre}>
                            {destino.nombre}
                        </MenuItem>
                    ))}
                </Select>

                <Typography sx={{ mt: 2 }}>
                    Motivo por el cual se devuelve el expediente {expedienteNombre}:
                </Typography>

                <TextField
                    label="Especificar motivo"
                    value={motivoPersonalizado}
                    onChange={(e) => setMotivoPersonalizado(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                />


                {error && <Typography color="error">{error}</Typography>}

                <Box display="flex" justifyContent="flex-end" sx={{ mt: 4 }}>
                    <Button
                        variant="outlined"
                        className="button-close"
                        onClick={() => {
                            handleClose();
                            setMotivo('');
                        }}
                        sx={{ mr: 2 }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        className="button-save"
                        onClick={handleConfirm}
                        disabled={isLoading}
                        sx={{ mr: 2 }}
                    >
                        {isLoading ? 'Devolviendo...' : 'Devolver'}
                    </Button>
                </Box>
            </Box>
        </MuiModal>
    );
};

export default ReturnBandejasModal;
