import { Box, Button, Card, CardContent, Divider, Grid, Typography } from "@mui/material";
import { useState, useRef } from "react";
import { createDocument } from "./DigitalizacionService";
import { useToastAlert } from '../../../../components/Alert/ToastAlertSave/ToastAlertSave';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ConfirmationDialog from "../../../../components/Alert/ConfirmationDialog";
import Acciones from "../../../../components/Acciones/Acciones";

const Digitalizacion = ({ registro, token, onClose }) => { 
  const [formData, setFormData] = useState({
    id: registro?.id || '',
    folio: registro?.folio || '',
    expediente: registro?.expediente || '',
    tipo: registro?.tipoEntrada || '',
    file: "",
  });

  const { showSnackbar } = useToastAlert();
  const [dialogOpen, setDialogOpen] = useState(false);
  const fileInputRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!formData.file.name) {
      showSnackbar('Por favor, cargue un archivo PDF antes de guardar.', 'error');
      return;
    }

    let dataSend = new FormData();
    
    dataSend.append("file", formData.file);

    try {
      await createDocument(token, dataSend, formData.id);
      showSnackbar('Registro guardado con éxito', 'success');
      onClose(); // Cierra el componente al guardar
    } catch (error) {
      console.error("Error al guardar el documento:", error);
      showSnackbar('¡Algo salió mal!', 'error');
    }
  };

  const handleConfirmDelete = (event) => {

    setFormData((prevState) => ({
      ...prevState,
      file: "",
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    if (file.size > 50 * 1024 * 1024) {
      showSnackbar('El archivo debe ser menor a 50 MB', 'error');
      return;
    }
    if (file.type !== "application/pdf") {
      showSnackbar("Solo se permiten archivos PDF.", "error");
      return;
    }

    setFormData((prevState) => ({
      ...prevState,
      file: file,
    }));
  };

  const handleDownloadPdf = (event) => {
    var blob = new Blob([formData.file], { type: "application/pdf" });
    let link = document.createElement("a");

    link.href = window.URL.createObjectURL(blob);
    link.target = "digitalizacion.pdf";
    link.click();
  }

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Acciones accion1={handleSubmit} accion2={onClose} hasDateAndTime={false}></Acciones>
      <Grid container spacing={2} sx={{ marginTop: 1 }}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">{`Folio: ${formData.folio}`}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">{`Expediente: ${formData.expediente}`}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">{`Tipo: ${formData.tipo}`}</Typography>
        </Grid>
      </Grid>

      <Box sx={{ marginTop: 3 }}>
        <Divider />
      </Box>
      <Box>
        <Typography variant="body2" sx={{ fontWeight: "bold" }}>
          De clic en el botón "Cargar archivo", posteriormente de clic en el botón de guardar.
        </Typography>

        <Box mt={2}>
          <Button variant="contained" component="label" className="button-save">
            Cargar archivo
            <input type="file" accept="application/pdf" hidden onChange={handleFileChange} ref={fileInputRef} />
          </Button>
        </Box>

        {formData.file.name && (
          <Grid container spacing={3} sx={{ marginTop: 1 }}>
            <Grid item >
              <Card sx={{ maxWidth: 250, margin: "0 auto", position: "relative" }}>

                <Typography
                  variant="body4"
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    cursor: "pointer",
                    fontWeight: "bold",
                    color: "red",
                    zIndex: 1,
                  }}
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                >
                  X
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: 100 }}>
                  <PictureAsPdfIcon sx={{ fontSize: 80, color: "black" }} />
                </Box>

                <CardContent>
                  <Typography
                    variant="body1"
                    sx={{
                      color: 'blue',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                      maxWidth: '200px',
                    }}
                    onClick={handleDownloadPdf}
                  >
                    { formData.file.name }
                  </Typography>

                </CardContent>
              </Card>
            </Grid>
          </Grid>

        )}
      </Box>

      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmDelete}
        message={`El registro seleccionado será permanentemente eliminado del sistema.`}
      />
    </>
  );
};

export default Digitalizacion;
