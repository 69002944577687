const breadcrumbConfig = {
    '/': 'Inicio',
    '/home': 'Inicio',
    '/api/core/juzgados': 'Órgano jurisdiccional',
    '/api/core/oficialias': 'Oficialías',
    '/api/core/personas': 'Personal',
    '/api/core/instituciones': 'Instituciones',
    '/api/core/materias': 'Materias',
    '/api/core/oficialias/crear_oficialia': 'Agregar oficialía',
    '/api/core/oficialias/editar_oficialia': 'Editar oficialía',
    '/api/core/instituciones/crear_institucion': 'Agregar institución',
    '/api/core/instituciones/editar_institucion': 'Editar institución',
    '/api/core/juzgados/crear_juzgado': 'Agregar órgano jurisdiccional',
    '/api/core/juzgados/editar_juzgado': 'Editar juzgado',
    '/api/core/sedes': 'Sedes',
    '/api/core/sedes/add': 'Agregar sede',
    '/api/core/sedes/edit': 'Editar sede',
    '/api/workflow/demanda': 'Demanda',
    '/api/workflow/exhorto': 'Exhorto',
    '/api/core/salas': 'Salas',
    '/api/core/salas/crear_salas': 'Agregar sala',
    '/api/core/salas/editar_salas': 'Editar sala',
    '/api/core/bloques': 'Bloques',
    '/api/core/bloques/add': 'Agregar bloque',
    '/api/core/bloques/edit': 'Editar bloque',
    '/api/workflow/promocion': 'Promoción',
    '/api/bandeja/historial': 'Historial',
    '/api/bandeja/entrada': 'Entrada',
    '/api/bandeja/salida': 'Salida',
    '/api/bandeja/oficios': 'Oficios',
    '/api/workflow/apelacion': 'Apelación',
    '/api/bandeja/recepcion': 'Recepción',
    '/api/bandeja/asignados': 'Asignado',
    '/api/bandeja/audiencias': 'Audiencias',
    '/api/workflow/registro': 'Expedientes antiguos',
    '/api/workflow/documentoContenido': 'Bandeja / Oficios / Editar oficio',
    '/expediente/:expedienteSeleccionado/api/agregar-participante': 'Expediente / Agregar participante',
    '/api/workflow/oficio': 'Oficio',
    '/api/bandeja/entrada/correccion': 'Promoción',
    '/api/bandeja/entrada/correccion/promocion': 'Corrección anexos',
    '/api/workflow/exhorto/edit': 'Corrección anexos',
    '/api/workflow/librogobierno': 'Buscador',
    '/api/bandeja/notificaciones': 'Notificaciones',
    '/api/bandeja/reprogramarAudiencias' : 'Reprogramar audiencia',
    '/api/bandeja/audiencias/crearAudiencias': 'Crear',
    '/api/core/calendarios': 'Calendarios',
    '/api/workflow/archivoJudicial': 'Archivo judicial',
    '/api/core/conceptos': 'Motivos de turnado',
    '/api/core/conceptos/crear_conceptos': 'Crear motivo de turnado',
    '/api/workflow/reasignacionExpediente': 'Reasignación de expediente',
    '/api/workflow/envios': 'Bandeja de envíos',
    '/api/workflow/generacionEtiquetas': 'Generación de etiquetas',
    '/api/bandeja/devueltos': 'Bandeja devueltos'
};
  
export default breadcrumbConfig;