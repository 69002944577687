import React, { useState, useEffect, useContext } from "react";
import {
  TextField,
  Chip,
  Box,
  Grid,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  FormLabel,
  Typography,
} from "@mui/material";
import { fetchProcedimientosByTipoJuicio } from "../../../../../../shared/services/ProcedimientoService";
import { fetchRubrosByProcedimiento } from "../../../../../../shared/services/RubroService";
import { fetchEtapasProcesales } from "../../../../../../shared/services/EtapaProcesalService";
import KeycloakConf from '../../../../../../api/keycloakConf';

const DetallePenal = ({
  formData, 
  setFormData, 
  tiposJuicio,
  general
}) => {
  const [rubroToAdd, setRubroToAdd] = useState("");
  const [tipoProcedimiento, setTipoProcedimiento] = useState([]);
  const [etapaProcesal, setEtapaProcesal] = useState([]);
  const [tipoRubros, setTipoRubros] = useState([]);
  const { getToken } = useContext(KeycloakConf);
  const token = getToken();
  const [procedimientoFlag, setProcedimientoFlag] = useState(false);
  const [etapaFlag, setEtapaFlag] = useState(false);
  const [rubrosResetFlag, setRubrosResetFlag] = useState(false);
  
  useEffect(() => {
    if(formData.tipoJuicioId){
      loadEtapasProcesales();
    }
  }, [token, formData.tipoJuicioId]);
  
  useEffect(() => {
    if(formData.tipoJuicioHijoId){
      loadProcedimiento();
    }
  }, [token, formData.tipoJuicioHijoId]);
  
  useEffect(() => {
    if (formData.procedimiento && formData.procedimiento.id) {
      loadRubros();
      if(rubrosResetFlag)
        setFormData((prevData) => ({
          ...prevData,
          rubros: []  
        }));
    }
  }, [token, formData.procedimiento]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if(name === "procedimiento" ){
      setRubrosResetFlag(true);
    }
  };
  
  const generateMenuItems = (items, key, labelKey = "name") => {
    return items.map((item) => (
      <MenuItem key={item[key]} value={item}>
        {item[labelKey]}
      </MenuItem>
    ));
  };
  
  const isValueInList = (list, value) => {
    return list.some((item) => JSON.stringify(item) === JSON.stringify(value));
  };
  useEffect(() => {
    if (!isValueInListTipoJuicio(tiposJuicio, formData.tipoJuicioHijoId)) {
      setFormData((prevData) => ({
        ...prevData,
        tipoJuicioHijoId: ""
      }));
    }
  }, [tiposJuicio]);
  
  useEffect(() => {
    if (!isValueInList(tipoProcedimiento, formData.procedimiento) && procedimientoFlag) {
      setFormData((prevData) => ({
        ...prevData,
        procedimiento: ""
      }));
    }
    const selectedProcedimiento = tipoProcedimiento.find(
      (procedimiento) => procedimiento.name === formData.tipoProcedimiento
    );
    if (selectedProcedimiento) {
      setFormData((prevData) => ({
        ...prevData,
        procedimiento: selectedProcedimiento,
      }));
    }
  }, [tipoProcedimiento]);
  
  useEffect(() => {
    if (!isValueInList(etapaProcesal, formData.etapaProcesal) && etapaFlag) {
      setFormData((prevData) => ({
        ...prevData,
        etapaProcesal: ""
      }));
    }
    if(etapaProcesal && formData.etapaProcesal && formData.etapaProcesal.id){
      const selectedEtapa = etapaProcesal.find(
        (etapa) => etapa.id === formData.etapaProcesal.id
      );
      if (selectedEtapa) {
        setFormData((prevData) => ({
          ...prevData,
          etapaProcesal: selectedEtapa,
        }));
      }
    }
  }, [etapaProcesal]);
  
  const isValueInListTipoJuicio = (list, value) => {
    return list.some((item) => item.id === value);
  };
  
  const loadProcedimiento = async () => {
    try {
      const response = await fetchProcedimientosByTipoJuicio(token, formData.tipoJuicioHijoId);
      setTipoProcedimiento(response);
      setProcedimientoFlag(true);
    } catch (error) {
      console.error("Error al obtener los tipos de procedimiento");
    }
  };
  
  const loadRubros = async () => {
    try {
      const response = await fetchRubrosByProcedimiento(token, formData.procedimiento.id);
      setTipoRubros(response);
    } catch (error) {
      console.error("Error al obtener los rubros");
    }
  };
  
  const loadEtapasProcesales = async () => {
    try {
      const response = await fetchEtapasProcesales(token, formData.tipoJuicioId);
      setEtapaProcesal(response);
      setEtapaFlag(true);
    } catch (error) {
      console.error("Error al obtener las etapas procesales");
    }
  };
  
  const handleSelectRubro = (event) => {
    const selectedRubro = event.target.value;
    if (!formData.rubros.find((r) => r.id === selectedRubro.id)) {
      setFormData((prevData) => ({
        ...prevData,
        rubros: [...prevData.rubros, selectedRubro]
      }));
      setTipoRubros(tipoRubros.filter((r) => r.id !== selectedRubro.id));
    }
    setRubroToAdd("");
  };
  
  const handleDeleteRubro = (rubro) => {
    setFormData((prevData) => ({
      ...prevData,
      rubros: prevData.rubros.filter((r) => r.id !== rubro.id)
    }));
    setTipoRubros([...tipoRubros, rubro]);
  };

  return (
<Grid container spacing={2} sx={{ marginTop: 1 }}>
      <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Juez"}>
        <TextField
            name="juezAsignado"
            label="Juez"
            variant="outlined"
            fullWidth
            value={formData.juezAsignado || ""}
            onChange={handleChange}
        />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Materia"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Materia"}:
          </Typography>
          <Typography>{general.materia || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Fecha de presentación"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Fecha de presentación"}:
          </Typography>
          <Typography>{general.fechaPresentacion || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Ubicación"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Ubicación"}:
          </Typography>
          <Typography>{formData.ubicacion || ""}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel id="determinacion-label">Determinación</InputLabel>
            <Select
              labelId="determinacion-label"
              name="determinacion"
              value={formData.determinacion || "PRESENTACION"}
              onChange={handleChange}
              label="Determinación"
            >
                <MenuItem key={"PRESENTACION"} value={"PRESENTACION"}>{"Presentación"}</MenuItem>
                <MenuItem key={"ADMITIDA"} value={"ADMITIDA"}>{"Admitida"}</MenuItem>
                <MenuItem key={"ADMITIDA_CON_PREVENCION"} value={"ADMITIDA_CON_PREVENCION"}>{"Admitida con prevención"}</MenuItem>
                <MenuItem key={"DESECHADA"} value={"DESECHADA"}>{"Desechada"}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"fechaAdmision"}>
          <Typography sx={{ fontWeight: "bold", mb: 1 }}>
            {"Fecha admisión"}:
          </Typography>
          <Typography>{formData.fechaAdmision || "-"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl fullWidth variant="outlined">
            <InputLabel id="tipoJuicioHijoId-label">Tipo de causa</InputLabel>
            <Select
              labelId="tipoJuicioHijoId-label"
              name="tipoJuicioHijoId"
              value={formData.tipoJuicioHijoId || ""}
              onChange={(e) => {
                const selectedId = e.target.value;
                setFormData((prevData) => ({
                  ...prevData,
                  tipoJuicioHijoId: selectedId,
                }));
              }}
              label="Tipo de juicio"
            >
              <MenuItem value="">Seleccionar</MenuItem>
              {tiposJuicio.map((tipo) => (
                <MenuItem key={tipo.id} value={tipo.id}>
                  {tipo.nombre}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl
            fullWidth
            variant="outlined"
          >
            <InputLabel id="tipo-label">Procedimiento</InputLabel>
            <Select
              labelId="procedimiento-label"
              name="procedimiento"
              value={formData.procedimiento || ""}
              onChange={handleChange}
              label="Procedimiento"
            >
              <MenuItem value="">Seleccionar</MenuItem>
              {generateMenuItems(tipoProcedimiento, "id")}
            </Select>
          </FormControl>
        </Grid>
        {formData.sentencia?
          <Grid item xs={12} sm={6} md={4}>
            <Typography sx={{ fontWeight: 'bold', mb: 1 }}>
                {"Sentencia"}:
            </Typography>
            <Typography>{general.sentencia}</Typography>
          </Grid>
          :<></>
        }
        {formData.fechaDesechado? 
          <Grid item xs={12} sm={6} md={4} sx={{ mb: 2 }} key={"Fecha desechada"}>
            <Typography sx={{ fontWeight: "bold", mb: 1 }}>
              {"Fecha de desechada"}:
            </Typography>
            <Typography>{formData.fechaDesechado || ""}</Typography>
          </Grid>
          :
          <></>
        }
      <Grid item xs={12} sm={12} md={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}>
            <FormLabel component="legend">Agregar delitos:</FormLabel>
            <Select
              value={rubroToAdd}
              onChange={handleSelectRubro}
              displayEmpty
              renderValue={
                rubroToAdd !== "" ? undefined : () => "Seleccionar Rubros"
              }
              labelId="rubro-label1"
              name="rubroId"
              fullWidth
            >
              <MenuItem value="">
                <em>Seleccionar</em>
              </MenuItem>
              {tipoRubros.map((r) => (
                <MenuItem key={r.id} value={r}>
                  {r.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <FormLabel component="legend">Delitos seleccionados:</FormLabel>
              <Box>
                {formData.rubros &&
              formData.rubros.map((rubro) => (
                  <Chip
                    key={rubro.id}
                    label={rubro.name}
                    onDelete={() => handleDeleteRubro(rubro)}
                    style={{ marginRight: 5, marginBottom: 5 }}
                  />
                ))}
              </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <FormControl
          fullWidth
          variant="outlined"
        >
          <InputLabel id="tipo-label">Etapa procesal</InputLabel>
          <Select
            labelId="etapa-label"
            name="etapaProcesal"
            value={formData.etapaProcesal || ""}
            onChange={handleChange}
            label="Etapa Procesal"
          >
            <MenuItem value="">Seleccionar</MenuItem>
            {generateMenuItems(etapaProcesal, "id", "etapaProcesal")}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          name="promovente"
          label="Promovente"
          variant="outlined"
          fullWidth
          value={formData.promovente || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          name="numeroCarpetaInvestigacion"
          label="Número de carpeta de investigación"
          variant="outlined"
          fullWidth
          value={formData.numeroCarpetaInvestigacion || ""}
          onChange={handleChange}        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          name="numeroOficio"
          label="Número de oficio"
          variant="outlined"
          fullWidth
          value={formData.numeroOficio || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          name="lugarHecho"
          label="Lugar del hecho"
          variant="outlined"
          fullWidth
          value={formData.lugarHecho}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={4}>
        <TextField
          name="fechaHecho"
          label="Fecha del hecho"
          variant="outlined"
          fullWidth
          value={formData.fechaHecho || ""}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <TextField
          name="observaciones"
          label="Observaciones"
          variant="outlined"
          fullWidth
          value={formData.observaciones || ""}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
};

export default DetallePenal;
