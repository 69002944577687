import axios from "../../api/axiosConfig";

const urlGetBandejaDevueltos = process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/bandeja/devueltos/ocp";
const urlPostDevueltos = process.env.REACT_APP_TRIALS_API_URL + "/api/workflow/devolver/bandejas";

export const fetchListBandejaDevueltos = async (token, page = 0, size = 10, sort = '', key = '') => {
    try {
      const response = await axios.get(urlGetBandejaDevueltos, { 
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          page,
          size,
          sort,
          key
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error al listar bandeja devueltos:', error);
      throw error;
    }
  };


  export const fetchDevolverABandeja = async (token, data) => {
      try {
          const response = await axios.post(urlPostDevueltos, data, {
              headers: {
                  Authorization: `Bearer ${token}`,
                  'Content-Type': 'application/json'
              }
          });
          return response.data;
      } catch (error) {
          console.error('Error al realizar la devolución:', error);
          throw error;
      }
  };
  