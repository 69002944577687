import { Modal as MuiModal, Box, Typography, Button, IconButton, Divider, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UpdateEstatusAnexos } from '../../pages/Bandeja/BandejaService';
import keycloakConf from '../../api/keycloakConf';
import { useContext } from 'react';
import { useToastAlert } from '../Alert/ToastAlertSave/ToastAlertSave';
import { hasRole } from '../../shared/Util';
import { movimientoPersonalJuzgado } from '../../shared/services/DocumentoService';


const RecepcionMultipleModal = ({ isInterno, isOpen, handleClose, items }) => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'white',
        borderRadius: '8px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        p: 4,
        maxWidth: '900px',
        width: '100%',
    };
    const { getToken } = useContext(keycloakConf);
    const { showSnackbar } = useToastAlert();

    const handleConfirm = async () => {
        const isInterno = items.some(item => item.isInterno == true);
        const token = getToken();
        let formData;

        if (isInterno) {
            formData = items.map(item => {
                return {
                    idDocumentoRecepcion: item.idDocumentoRecepcion,
                    idConcepto: item.idConcepto
                }
            })
            try {
                const result = await movimientoPersonalJuzgado(token, formData);
                console.log(result);
                
                showSnackbar('Documento asignado con éxito', 'success');
                handleClose();
            } catch (error) {
                showSnackbar('Hubo un error al asignar el documento', 'error');
            }
        } else {
            formData = items.map(item => {
                const newData = {
                    documentoId: item.id,
                    anexos: item.anexos || [],
                    observaciones: item.observaciones || "",
                    recomendaciones: item.recomendaciones || ""
                }
                return newData;
            })
            try {
                await UpdateEstatusAnexos(token, formData);

                showSnackbar("Correspondencia recibida correctamente.", "success");
                handleClose();
            } catch (error) {
                console.log("Error al recepcionar la correspondencia: ", error);

                showSnackbar("Ha ocurrido error", "success");
            }
        }
    }



    const renderTable = () => {
        return (
            <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">No. Expediente</TableCell>
                            <TableCell align="center">Tipo de entrada</TableCell>
                            <TableCell align="center">Origen</TableCell>
                            {hasRole("OFICIAL_MAYOR") ? (<>
                                <TableCell align="center">Observaciones</TableCell>
                                <TableCell align="center">Recomendaciones</TableCell>
                                <TableCell align="center">Anexos</TableCell>
                            </>) : (<>
                                <TableCell align="center">Motivo de turnado</TableCell>
                            </>)}

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map(item => (

                            <TableRow key={item.id}>
                                <TableCell align="center">{item.expediente}</TableCell>
                                <TableCell align="center">{item.tipoEntrada}</TableCell>
                                <TableCell align="center">{item.origen}</TableCell>
                                {hasRole("OFICIAL_MAYOR") ? (<>
                                    <TableCell align="center">{item.observaciones || "N/A"}</TableCell>
                                    <TableCell align="center">{item.recomendaciones || "N/A"}</TableCell>
                                    <TableCell align="center">{item.anexos?.map(anexo => anexo.nombre).join(", ") || "N/A"}</TableCell>
                                </>) : (<>
                                    <TableCell align="center">{item.conceptoSelected}</TableCell>
                                </>)}

                            </TableRow>
                        ))}

                        {items.length === 0 && (
                            <TableRow>
                                <TableCell colSpan={8} align="center">
                                    No hay registros disponibles.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }


    return (
        <MuiModal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={style}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', color: '#333' }}>
                        Recepción de correspondencia
                    </Typography>
                    <IconButton onClick={() => {
                        handleClose();
                    }}>
                        <CloseIcon />
                    </IconButton>
                </Box>

                <Divider sx={{ my: 1 }} />

                {renderTable()}

                <Box display="flex" justifyContent="flex-end" sx={{ mt: 2 }} >
                    <Button
                        variant="outlined"
                        className="button-close"
                        onClick={() => { handleClose(); }}
                        sx={{ mr: 2 }}
                    >
                        Cancelar
                    </Button>
                    <Button
                        variant="contained"
                        className="button-save"
                        onClick={handleConfirm}
                        sx={{ mr: 2 }}
                    >
                        Recibir
                    </Button>
                </Box>
            </Box>


        </MuiModal>
    )

}

export default RecepcionMultipleModal;