import { useContext, useEffect, useState } from "react";
import { fetchListBandejaDevueltos } from "./BandejaDevueltosService";
import keycloakConf from "../../api/keycloakConf";
import { Box } from "@mui/material";
import RefreshButton from "../../components/Button/RefreshButton";
import SearchInput from "../../components/Search/SearchInput";
import BandejaDevueltosTable from "./BandejaDevueltosTable";

const BandejaDevueltos = () => {
    const [bandejaDevueltos, setBandejaDevueltos] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { getToken } = useContext(keycloakConf);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);

    useEffect(() => {
        loadBandejaDevueltos();
    }, [])

    const loadBandejaDevueltos = async () => {
        try {
            const token = getToken();
            const response = await fetchListBandejaDevueltos(token, page, rowsPerPage, '', searchQuery);
            setBandejaDevueltos(response.content);
            setPageable(response.page);

        } catch (error) {
            console.log("Ha ocurrido un error.", error);
        }

    }

    const handleRefresh = () => {
        setSearchQuery('');
        setPage(0);
        loadBandejaDevueltos();
    };

    return <>
        <Box mb={2} display="flex" justifyContent="space-between" alignItems="center">
            <Box display="flex" alignItems="center">
                <RefreshButton onClick={handleRefresh} />
            </Box>


            <Box flexBasis="300px" ml={2}>
                <SearchInput
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder="Buscar..."
                    tooltipTitle="Escriba para buscar"
                    onKeyDown={(e) => e.key === 'Enter' && loadBandejaDevueltos()}
                />
            </Box>
        </Box>

        <Box>

            <BandejaDevueltosTable
                bandejaDevueltos={bandejaDevueltos}
                pageable={pageable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                handleRefresh={handleRefresh}    
            />

        </Box>
    </>
}


export default BandejaDevueltos;