import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { AppBar, Toolbar, Typography, IconButton, Box, Menu, MenuItem, Button } from '@mui/material';
import { styled } from '@mui/system';
import AccountCircle from '@mui/icons-material/AccountCircle';
import keycloakConf from '../../api/keycloakConf';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import MenuPJEP from '../Menu/Menu';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  color: 'black',
  zIndex: 1201,
  marginLeft: 240,
  boxShadow: '0 5px 5px rgba(87, 40, 141, 0.1)'
}));

const Header = (props) => {
  const { getToken, logout } = useContext(keycloakConf);

  const [anchorEl, setAnchorEl] = useState(null);

  const drawerWidth = 240;
  const token = getToken();

  const userPayload = token ? JSON.parse(new TextDecoder().decode(Uint8Array.from(atob(token.split('.')[1]), c => c.charCodeAt(0)))) : null;
  const firstName = userPayload ? userPayload.given_name : 'Nombre no disponible';
  const lastName = userPayload ? userPayload.family_name : 'Apellido no disponible';
  const userEmail = userPayload ? userPayload.email : 'Correo no disponible';
  const [centroTrabajo, setCentroTrabajo] = useState("");  
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  useEffect(() => {
    if (sessionStorage.getItem("centroTrabajo") == null) {
      getCentrosDeTrabajo();
    }
  }, [])

  const getCentrosDeTrabajo = async () => {
    try {
      const token = getToken();
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/personas/centroTrabajo/login`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      
      const centroTrabajoTemp = response.data[0].nombre;
      sessionStorage.setItem("centroTrabajo", JSON.stringify(centroTrabajoTemp));
      setCentroTrabajo(centroTrabajoTemp);
    } catch (error) {
      console.log("Error al obtener el centro de trabajo");
    }
  }

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const drawer = (
    <MenuPJEP />
  );
  // Remove this const when copying and pasting into your project.
  const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <StyledAppBar position="fixed">
      <Drawer
        PaperProps={{
          style: {
            width: drawerWidth,
            backgroundColor: '#ebe6d6',
            color: '#636569',
          },
        }}
        container={container}
        variant="temporary"
        open={mobileOpen}
        onTransitionEnd={handleDrawerTransitionEnd}
        onClose={handleDrawerClose}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          zIndex: 1300,
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        PaperProps={{
          style: {
            width: drawerWidth,
            backgroundColor: '#ebe6d6',
            color: '#636569',
          },
        }}
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>
      <Toolbar sx={{ color: '#fff', background: '#636569' }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Typography variant="h7">{firstName} {lastName}</Typography>
        <IconButton edge="end" color="inherit" onClick={handleClick}>
          <AccountCircle />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem disabled>{userEmail}</MenuItem>
          <MenuItem disabled>{centroTrabajo}</MenuItem>
          <MenuItem>
            <Button variant="outlined" sx={{ color: '#8c92bc', borderColor: '#8c92bc' }} className="button-save" onClick={() => {
              logout();
              handleClose();
            }}>
              Cerrar sesión
            </Button>
          </MenuItem>
        </Menu>
      </Toolbar>
    </StyledAppBar>
  );
};

export default Header;
