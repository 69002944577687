import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography, Box, Collapse, ListItemButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

export default function DemandaTable({ datos, openMaterias, setOpenMaterias, onJuicioSeleccionado }) {
  const navigate = useNavigate();

  const handleCellClick = (nombreJuicio, materia, juicioId) => {

    onJuicioSeleccionado({ id: juicioId, nombre: nombreJuicio, materia });
    
    navigate('/api/workflow/create-demanda', {
      state: { datos, nombreJuicio, materia, juicioId }
    })
  };
  

  const toggleMateria = (materia) => {
    setOpenMaterias(prevState => ({
      ...prevState,
      [materia]: !prevState[materia]
    }));
  };

  return (
    <Box mb={3} >
      <TableContainer component={Paper} style={{ boxShadow: ' 0px 0px 12px 0px hsl(0deg 0% 0% / 18%)', border: '2px solid #636569', }}>
        <Table>
          <TableBody>
            {datos.map(({ materia, juicios }) => (
              <React.Fragment key={materia}>
                <TableRow>
                  <TableCell colSpan={2} align="left">
                    <ListItemButton onClick={() => toggleMateria(materia)}>
                      <Typography component="p" sx={{ flexGrow: 1, fontSize: '1.1rem' }}>
                        {materia}
                      </Typography>
                      {openMaterias[materia] ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell colSpan={2} style={{ padding: 0 }}>
                    <Collapse in={openMaterias[materia]} timeout="auto" unmountOnExit>
                      <Table>
                        <TableBody>
                          {juicios.map((juicio) => (
                            <TableRow
                              key={juicio.id}
                              sx={{
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.100)',
                                  cursor: 'pointer',
                                },
                              }}
                              onClick={() => handleCellClick(juicio.nombre, materia, juicio.id)}
                            >
                              <TableCell>{juicio.nombre}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
