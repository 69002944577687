// Función para limpiar caracteres especiales en campos de texto
export const onlyText = (value) =>  value.normalize('NFC').replace(/[\u0300-\u036f]/gu, '').replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ´\s]/gu, ''); 

export const onlyNames = (value) =>  value.normalize('NFC').replace(/[\u0300-\u036f]/gu, '').replace(/[^a-zA-ZáéíóúÁÉÍÓÚñÑ´\s]/gu, ''); 
// Función para limpiar caracteres no numericos
export const onlyNumbers = (value) => value.replace(/\D/g, '');

// Función para limpiar caracteres no alfanuméricos
export const onlyAlphaNumeric = (value) => value.replace(/[^a-zA-Z0-9]/g, '');

// Función para limpiar caracteres no alfanuméricos y permite espacios
export const onlyAlphaNumericSpace = (value) => value.normalize('NFC').replace(/[^a-zA-Z0-9áéíóúÁÉÍÓÚñÑ\s.]/g, '');

// Funciones para correos electronicos
export const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
export const onlyEmail = (email) => email.trim();

//Función para permitir caracteres especiales: 
export const allowSpecialCharacters = (value) => value.normalize('NFC').replace(/[0-9]/g, '');