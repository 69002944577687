
export const hasPermission = (policyName, scope) => {
    if (sessionStorage.getItem("policies") != null) {
        const policies = JSON.parse(sessionStorage.getItem("policies"));
        for (const policy of policies.permission) {
            if (policy.name == policyName) {
                if (policy.scopes.includes(scope)) {
                    return true;
                }
            }
        }
    }
    return false;
};

export const hasRole = (roles) => {
    if (sessionStorage.getItem("policies") != null) {
        const policies = JSON.parse(sessionStorage.getItem("policies"));
        var array = roles.split(",");
        for (const role of array) {
            if (policies.roles.includes(role)) {
                return true;
            }
        }
    }
    return false;
};

export const convertirExpediente = (expediente) => {
    const longitud = 6;
    const expedienteStr = expediente.toString();

    if(expedienteStr.length >= longitud){
        return expedienteStr;
    }
    
    return expediente.padStart(longitud, '0');
};