import { useState, useEffect, useContext } from "react";
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import keycloakConf from "../../../api/keycloakConf";
import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
import { useNavigate } from "react-router-dom";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import RefreshButton from "../../../components/Button/RefreshButton";
import SendButton from "../../../components/Button/SendButton";
import text from "../../../assets/Glosario";
import ButtonsAccionRapida from "./ButtonsAccionRapida";
import ModalTurnado from "../../../components/ModalTurnarPersonalJuzgado/ModalTurnar";
import ModalPieza from "../../../components/ModalPieza/ModalPieza";
import ConfirmationDialog from "../../../components/Alert/ConfirmationDialog/ConfirmationDialogGeneric";
import { acoplarPieza } from "../../../shared/services/CarpetaService";
import { useItem } from "../../../components/ItemProvider/ItemContext";
import Breadcrumbs from "../../../components/Breadcrumb/breadcrumbs";
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const TableBandejaAsignados = ({
  bandeja,
  pageable,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  loadBandejaAsignados,
  loading,
  cartItems,
  setCartItems,
  setSearchQuery,
}) => {
  const { showSnackbar } = useToastAlert();
  const navigate = useNavigate();
  const [selected, setSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [titleDialogConfirm, setTitleDialogConfirm] = useState("");
  const [messageDialogConfirm, setMessageDialogConfirm] = useState("");
  const [buttonOkLabel, setButtonOkLabel] = useState("");
  const [buttonCancelLabel, setButtonCancelLabel] = useState("");
  const { getToken } = useContext(keycloakConf);

  const [openModalPieza, setOpenModalPieza] = useState(false);
  const [modalDataPieza, setModalDataPieza] = useState([]);
  const [openConfirmPieza, setOpenConfirmPieza] = useState(false);
  const [confirmDataPieza, setConfirmDataPieza] = useState([]);
  const [token, setToken] = useState({});
  const { item, setItem } = useItem();

  useEffect(() => {
    setToken(getToken());
  }, [getToken,]);

  const isSelected = (carpetaId, tipoEntrada) => {
    const uniqueId = `${carpetaId}-${tipoEntrada}`;
    return selected.indexOf(uniqueId) !== -1;
  };

  const handleOpenModal = (items, modal = "") => {

    if (modal && modal === "pieza") {
      setModalDataPieza(items);
      setOpenModalPieza(true);

      return;
    }

    if (modal && modal === "confirm") {
      setOpenConfirmPieza(true);
      setConfirmDataPieza(items);

      return;
    }

    setModalData(items);
    setOpenModal(true);
  };

  const handleCloseModal = (event, modal = "") => {

    if (modal && modal === "pieza") {
      setOpenModalPieza(false);
      setModalDataPieza({});
    }

    if (modal && modal === "confirm") {
      setOpenConfirmPieza(false);
    }

    setOpenModal(false);
    setCartItems([]);
  };

  const handleSelectRow = (carpetaId, id, tipoEntrada) => {
    const uniqueId = `${carpetaId}-${tipoEntrada}`;
    setSelected((prevSelected) =>
      prevSelected.includes(uniqueId)
        ? prevSelected.filter((selectedId) => selectedId !== uniqueId)
        : [...prevSelected, uniqueId]
    );
  };

  const handleSingleTurnar = (id) => {
    const itemToAdd = bandeja.find((item) => item.carpetaId === id);
    const isItemInCart = cartItems.some((cartItem) => cartItem.carpetaId === id);

    if (!isItemInCart && itemToAdd) {
      setCartItems((prevItems) => [...prevItems, itemToAdd]);
      handleOpenModal([itemToAdd]);
    }
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      const newSelecteds = bandeja
        .map((item) => `${item.carpetaId}-${item.tipoEntrada}`)
        .filter((itemId) => itemId !== null);
      setSelected(newSelecteds);
    } else {
      setSelected([]);
    }
  };

  const handleRefresh = () => {
    setSearchQuery('');
    setPage(0);
    loadBandejaAsignados();
  };

  const addToTurnado = () => {
    const selectedItems = bandeja.filter((item) =>
      selected.includes(`${item.carpetaId}-${item.tipoEntrada}`)
    );
    const newItemsToAdd = selectedItems.filter(
      (item) => !cartItems.some((cartItem) => cartItem.id === item.id)
    );
  
    setCartItems([...cartItems, ...newItemsToAdd]);
    setSelected([]);
    if (newItemsToAdd.length > 0) {
      handleOpenModal(newItemsToAdd);
    } else {
      showSnackbar('Seleccione al menos un registro', 'error');
    }
  };

  const handleConfirmPieza = async () => {
    try {
      await acoplarPieza(token, confirmDataPieza);

      if (confirmDataPieza.estatus === "CANCELADO")
        showSnackbar('Pieza cancelada', 'success');
      else
        showSnackbar('Pieza intregada exitosamente', 'success');

      handleRefresh();
    } catch (error) {
      console.log(error);
      if (error.response) {
        showSnackbar(error.response.data.detail, error);
      } else {
        showSnackbar("¡Algo salió mal!", error);
      }
    }
  }

  const verExpediente = (expediente) => {
    if (expediente.tipoEntrada !== 'Promocion' && expediente.estatus !== 'Turnado') {
      setItem(expediente);
      navigate('/api/bandeja/expediente', { state: { item: expediente, tabnav: 0 } });
    }
  }

  const isCarpetaAsignadaPromocion = (carpetaId) => {
    const result = bandeja.find((element) => element.carpetaId === carpetaId && element.tipoEntrada === 'Demanda' && element.estatus === 'Asignado');

    console.log(result);
    return result !== undefined;
  }

  return (
    <>
      <Box mb={2} display="flex" alignItems="center">
      <Breadcrumbs istitulo="true" className="title2"/>
        <Box display="flex" alignItems="center" sx={{marginLeft:2}}>
          <RefreshButton onClick={handleRefresh} />
        </Box>

        <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
          <SendButton onClick={addToTurnado} />
          <ModalTurnado
            open={openModal}
            onClose={handleCloseModal}
            data={modalData}
            handleRefresh={handleRefresh}
          />
          <ModalPieza
            open={openModalPieza}
            onClose={handleCloseModal}
            data={modalDataPieza}
            handleRefresh={handleRefresh}
          />
        </Box>
      </Box>

      <Box>
        {loading ? (
          <p>Cargando ... </p>
        ) : (
          <Box sx={{ width: "100%" }}>
            <Paper sx={{ width: "100%", mb: 2 }}>
              <PaginationGeneric
                pageable={pageable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
              <TableContainer sx={{ maxHeight: 600, overflowY: "auto" }}>
                <Table stickyHeader sx={{ minWidth: 750 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell className="table-header" padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selected.length > 0 &&
                            selected.length < bandeja.length
                          }
                          checked={
                            bandeja.length > 0 &&
                            selected.length === bandeja.length
                          }
                          onChange={handleSelectAll}
                        />
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.NoExpediente}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.TipoEntrada}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.Concepto}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.FechaTurnado}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.FechaTermino}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.Estatus}
                      </TableCell>
                      <TableCell className="table-header" align="center">
                        {text.Acciones}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {bandeja.map((item) => {
                      const isItemSelected = isSelected(item.carpetaId);
                      return (
                        <StyledTableRow
                          key={item.id}
                          hover
                          style={{ cursor: "pointer" }}
                          selected={isSelected(item.carpetaId, item.tipoEntrada)}
                          onClick={(e) => {
                            verExpediente(item);
                          }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isSelected(item.carpetaId, item.tipoEntrada)}
                              disabled={item.estatus === "Turnado"}
                              onClick={(e) => e.stopPropagation()}
                              onChange={() => handleSelectRow(item.carpetaId, item.id, item.tipoEntrada)}
                            />
                          </TableCell>
                          <TableCell align="center">
                            {item.expediente}
                          </TableCell>
                          <TableCell align="center">
                            {item.tipoEntrada === "Promocion" ? 'Promoción' : item.tipoEntrada}
                          </TableCell>
                          <TableCell align="center">{item.concepto}</TableCell>
                          <TableCell align="center">
                            {new Date(item.fechaTurnado).toLocaleString()}
                          </TableCell>
                          <TableCell align="center">
                            {item.fechaTermino != null && (new Date(item.fechaTermino).toLocaleString())}
                            {item.fechaTermino == null && ("-")}
                          </TableCell>
                          <TableCell align="center">
                            {item.estatus}
                          </TableCell>
                          <TableCell align="center">
                            <ButtonsAccionRapida
                              item={item}
                              handleSingleTurnar={handleSingleTurnar}
                              handleOpenModal={handleOpenModal}
                              handleCloseModal={handleCloseModal}
                              handleConfirmPieza={handleConfirmPieza}
                              setMessageDialogPieza={setMessageDialogConfirm}
                              setTitleDialogConfirm={setTitleDialogConfirm}
                              setButtonCancelLabel={setButtonCancelLabel}
                              setButtonOkLabel={setButtonOkLabel}
                              handleRefresh={handleRefresh}
                              isCarpetaAsignadaPromocion={isCarpetaAsignadaPromocion}
                            />
                          </TableCell>
                        </StyledTableRow>
                      );
                    })}
                    {bandeja.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={8} align="center">
                          No hay registros disponibles.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <PaginationGeneric
                pageable={pageable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
              />
            </Paper>
          </Box>
        )}
      </Box>
      <ConfirmationDialog
        open={openConfirmPieza}
        onClose={(e) => handleCloseModal(e, "confirm")}
        onConfirm={handleConfirmPieza}
        message={messageDialogConfirm}
        title={titleDialogConfirm}
        btnLeft={buttonCancelLabel}
        btnRight={buttonOkLabel}
      />
    </>
  );
};

export default TableBandejaAsignados;
