import React from 'react';
import {
  Box,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  Tooltip,
} from '@mui/material';
import PaginationGeneric from '../../../components/Pagination/PaginationGeneric';
import text from '../../../assets/Glosario';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

export default function ConceptoTable({
    conceptos, 
    pageable, 
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    onUpdateStatus,
    onDelete,
    onEdit,
}) { 

    return( 
 <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
      <PaginationGeneric
          pageable={pageable}
          page={page}
          setPage={setPage}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
          <Table stickyHeader sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell className='table-header'>{text.motivoTurnado}</TableCell>
                <TableCell className='table-header'>{text.dias}</TableCell>
                <TableCell className='table-header'>{text.juicio}</TableCell>
                <TableCell className='table-header'>{text.Estatus}</TableCell>
                <TableCell className='table-header'>{text.Acciones}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
                { 
                 conceptos
                 .map((concepto) => (
                    <StyledTableRow
                    key={concepto.id}
                    onClick={() => onEdit(concepto)}
                    hover
                    style={{ cursor: 'pointer' }}
                  >
                    <TableCell>{concepto.concepto}</TableCell>
                    <TableCell>{concepto.dias}</TableCell>
                    <TableCell>{concepto.nombreTipoJuicio}</TableCell>
                    <TableCell>
                    {concepto.estado === "ACTIVE" ? <span>Activo</span> : <span>Inactivo</span>}
                    </TableCell>
        
                    <TableCell align="center">
                      <Stack direction="row">
                        <Tooltip title="Eliminar">
                            <DeleteIcon 
                            style={{ marginRight: '10px' }} 
                            className='icon-color' onClick={(event) => {
                            event.stopPropagation();
                            onDelete(concepto.id, concepto.concepto);
                          }}>
                          </DeleteIcon>
                        </Tooltip>

                        <Tooltip title={concepto.estado === 'ACTIVE' ? 'Desactivar' : 'Activar'}>
                          <CheckIcon  style={{ marginRight: '10px' }} className='icon-color' onClick={(event) => {
                           event.stopPropagation();
                           onUpdateStatus(concepto);

                          }}>
                          </CheckIcon>
                        </Tooltip>
                      </Stack>
                    </TableCell>
                   
                  </StyledTableRow> 
                 ))}
              
              {conceptos.length === 0 && (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No hay motivos de turnado disponibles.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <PaginationGeneric
          pageable = {pageable}
          page = {page}
          setPage = {setPage}
          rowsPerPage = {rowsPerPage}
          setRowsPerPage = {setRowsPerPage}
        />
      </Paper>
    </Box>

    );
}