import { Button, FormControl, Grid, TextField } from "@mui/material";
import { useContext, useState } from "react";
import { onlyNumbers } from "../../components/Caracteres/caracteresEspeciales";
import { validateFields } from "../../components/Alert/ValidationRequired/validationRequired";
import { useToastAlertValidation } from "../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import { useToastAlert } from "../../components/Alert/ToastAlertSave/ToastAlertSave";
import { getQRPrueba } from "./GeneradorEtiquetasService";
import keycloakConf from "../../api/keycloakConf";
const FormGeneradorEtiquetas = () => {
    const [formData, setFormData] = useState({
        expMin: "",
        expMax: "",
        year: ""
    });
    const [errors, setErrors] = useState([]);
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
     const { showSnackbar } = useToastAlert();
     const { getToken } = useContext(keycloakConf);
    const handleChangeField = (e) => {
        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: onlyNumbers(value)
        }))

    }

    const handleGenerarDocumento = async () => {
        try {
            const token = getToken();
            const documento = await getQRPrueba(token, formData.expMin, formData.expMax, formData.year);
            var blob = new Blob([documento], { type: "application/pdf" });
            let link = document.createElement("a");
        
            link.href = window.URL.createObjectURL(blob);
            link.target = "etiquetas.pdf";
            link.click();
            showSnackbar("Documento generado con éxito", "success");
        } catch (error) {
            console.log("error al generar documento", error);
            const razonError = error.response.status = 404 ? "No existen expedientes con los criterios de búsqueda." : "Ha ocurrido un error";
            showSnackbar(razonError, "error");
        }
       
    }


    const handleValidarCampos = () => {
        const rules = {
            'expMin': { required:true },
            'expMax': { required:true },
            'year': { required:true }
        }

        const errores = validateFields(formData, rules, showAlertValidate);
        setErrors(errores);
        if(Object.keys(errores).length === 0){
            handleGenerarDocumento();
        }
        
    }

    return (
        <>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item sm={4}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            label="Inicio"
                            name="expMin"
                            value={formData.expMin}
                            onChange={(e) => handleChangeField(e)}
                            placeholder="Ingrese el número de expediente de inicio"
                            margin="normal"
                            inputProps={{ maxLength: 6 }}
                            error={!!errors["expMin"]}
                            helperText={errors["expMin"] || ''}
                        />
                    </FormControl>
                </Grid>

                <Grid item sm={4}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            label="Fin"
                            name="expMax"
                            value={formData.expMax}
                            onChange={(e) => handleChangeField(e)}
                            placeholder="Ingrese el número de expediente del final"
                            margin="normal"
                            error={!!errors["expMax"]}
                            inputProps={{ maxLength: 6 }}
                            helperText={errors["expMax"] || ''}
                        />
                    </FormControl>
                </Grid>

                <Grid item sm={4}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            label="Año"
                            name="year"
                            value={formData.year}
                            onChange={(e) => handleChangeField(e)}
                            placeholder="Ingrese el año"
                            margin="normal"
                            inputProps={{ maxLength: 4 }}
                            error={!!errors["year"]}
                            helperText={errors["year"] || ''}
                        />
                    </FormControl>
                </Grid>

                <Grid item sm={4}>
                    <Button
                        type="button"
                        variant="contained"
                        className="button-save"
                        onClick={handleValidarCampos} 
                        style={{ marginLeft: "10px" }} 
                    >
                        Generar etiquetas
                    </Button>
                </Grid>
            </Grid>
        </>
    );

}

export default FormGeneradorEtiquetas;