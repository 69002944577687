import React, { useEffect, useState, useContext } from 'react';
import { Modal, Box, Button, FormControl, Select, MenuItem, Typography, Grid, FormHelperText, Autocomplete, TextField, CircularProgress } from '@mui/material';
import { getAllConceptos } from '../../shared/services/ConceptosService';
import { movimientoPersonalJuzgado } from '../../shared/services/DocumentoService';
import keycloakConf from '../../api/keycloakConf';
import { useToastAlert } from '../Alert/ToastAlertSave/ToastAlertSave'

const RecepcionModal = ({ open, onClose, item, handleRefresh, onSave }) => {
    const [conceptos, setConceptos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedConcepto, setSelectedConcepto] = useState("");
    const { getToken } = useContext(keycloakConf);
    const { showSnackbar } = useToastAlert();

    const token = getToken();

    useEffect(() => {
        if (open) {
            const fetchConceptos = async () => {
                setLoading(true);
                try {
                    const data = await getAllConceptos(token, item.id);
                    setConceptos(data);
                } catch (err) {
                    setError('Error al cargar conceptos');
                } finally {
                    setLoading(false);
                }
            };

            fetchConceptos();
        } else {
            setSelectedConcepto(1);
        }
    }, [open, token]);

    const handleChange = (newValue) => {
        setSelectedConcepto(newValue);
    };

    const handleReceive = async () => {

        const record = {
            idDocumentoRecepcion: item.id,
            idConcepto: selectedConcepto,
            conceptoSelected: conceptos.find(concepto => concepto.id === selectedConcepto).nombre
        };

        onSave(record);

    };

    if (!item) {
        return null;
    }

    return (
        <Modal
            open={open}
            onClose={() => {
                setSelectedConcepto("");
                onClose();
            }}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 600,
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                borderRadius: 2,
            }}>
                <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                    {item.prioridad === 'URGENTE' ? 'Urgente' : 'Recepción'}
                </Typography>

                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={3}>
                        <Typography variant="body1">De:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <Typography variant="body1" sx={{ marginLeft: .5 }}>{item.origen}</Typography>
                    </Grid>

                    <Grid item xs={3}>
                        <Typography variant="body1">Motivo turnado:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                        <FormControl fullWidth variant="outlined" error={!!error}>
                            <Autocomplete
                                value={conceptos.find(concepto => concepto.id === (selectedConcepto || item.concepto))}
                                onChange={(event, newValue) => handleChange(newValue ? newValue.id : null)}
                                options={conceptos}
                                getOptionLabel={(option) => option.nombre || ''}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Selecciona uno"
                                        variant="outlined"
                                        size="small"
                                        error={!!error}
                                        helperText={error}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: loading ? <CircularProgress size={20} /> : null,
                                        }}
                                    />
                                )}
                                renderOption={(props, option) => (
                                    <li {...props} key={option.id}>
                                        {option.nombre}
                                    </li>
                                )}
                                disableClearable
                                loading={loading}
                                noOptionsText="No hay opciones"
                            />
                            {error && <FormHelperText>{error}</FormHelperText>}
                        </FormControl>
                    </Grid>
                    {item.horas !== null && (
                        <Grid item xs={12} sx={{ marginTop: 3 }}>
                            <Typography variant="body1">
                                Tiempo de término:
                                <Box component="span" sx={{ marginLeft: 3, fontWeight: 'bold' }}>
                                    {item.horas} hrs
                                </Box>
                            </Typography>
                        </Grid>
                    )}
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'right', gap: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                        className="button-close"
                    >
                        Cerrar
                    </Button>
                    <Button
                        variant="contained"
                        className="button-save"
                        onClick={handleReceive}
                    >
                        Guardar
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default RecepcionModal;
