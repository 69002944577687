import React, { useState, useContext, useEffect, useRef } from "react";
import { TextField, Typography, Grid, Box, ToggleButtonGroup, ToggleButton, Divider } from "@mui/material";
import { addDocumento, getSello } from "../DemandaService";
import KeycloakConf from "../../../api/keycloakConf";
import { useLocation, useNavigate } from "react-router-dom";
import { validateFields } from "../../../components/Alert/ValidationRequired/validationRequired";
import { onlyNames, allowSpecialCharacters, onlyAlphaNumericSpace } from "../../../components/Caracteres/caracteresEspeciales";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { useToastAlertValidation } from "../../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import Breadcrumbs from "../../../components/Breadcrumb/breadcrumbs";
import EditIcon from '@mui/icons-material/Edit';

// formularios
import Actor from "./RegistrosDemanda/Actor";
import Demandado from "./RegistrosDemanda/Demandado";
import Anexo from "./RegistrosDemanda/Anexo";
// utils
import { crearActor, crearDemandado } from "./RegistrosDemanda/DemandaUtils/documentoUtils";
// states
import { initialActorFisica, initialActorMoral, initialDemandadoFisica, initialDemandadoMoral } from "./RegistrosDemanda/DemandaUtils/initialStates";
import Acciones from "../../../components/Acciones/Acciones";

export default function FormDemanda({
  isEditable,
  handleEditDemanda,
  demandaData,
  onSave,
  juicioId,
  selectCambioDemanda,
  setSaveLocalStorage,
  setCerrarFuncion,
  tipoDemandaSeleccionada
}) {
  const location = useLocation();
  const navigate = useNavigate();
  const { showSnackbar } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();
  const anexoRefs = useRef([]);
  const [anexosErrors, setAnexosErrors] = useState([]);

  const [actorTab, setActorTab] = useState(0);
  const [demandadoTab, setDemandadoTab] = useState(0);
  const [anexos, setAnexos] = useState([]);
  const { getToken } = useContext(KeycloakConf);
  const [modalOpen, setModalOpen] = useState(false);
  const [razonEdicion, setRazonEdicion] = useState("");
  const [isEditAnexos, setIsEditAnexos] = useState(false);
  const [isPreviewDemanda, setIsPreviewDemanda] = useState(false);

  const [actorFisica, setActorFisica] = useState(initialActorFisica);
  const [actorMoral, setActorMoral] = useState(initialActorMoral);
  const [demandadoFisica, setDemandadoFisica] = useState(initialDemandadoFisica);
  const [demandadoMoral, setDemandadoMoral] = useState(initialDemandadoMoral);

  const [errors, setErrors] = useState({});
  const handleActorToggleChange = (event, newValue) => {
    if (newValue !== null) {
      setActorTab(newValue);
    }
  };

  useEffect(() => {
    if (demandaData) {
      // Si la demandaData tiene información, actualiza los estados , este solo es para editar ya que solo trae los valores al form
      if (demandaData.actor.tipoPersona === "fisica") {
        setActorFisica({
          nombreActor: demandaData.actor.nombre,
          apellidoPaternoActor: demandaData.actor.apellidoPaterno,
          apellidoMaternoActor: demandaData.actor.apellidoMaterno,
          pseudonimoActor: demandaData.actor.pseudonimo,
        });
        setActorTab(0);
      } else if (demandaData.actor.tipoPersona === "moral") {
        setActorMoral({
          nombreActorMoral: demandaData.actor.nombre,
        });
        setActorTab(1);
      }

      if (demandaData.demandado.tipoPersona === "fisica") {
        setDemandadoFisica({
          nombreDemandado: demandaData.demandado.nombre,
          apellidoPaternoDemandado: demandaData.demandado.apellidoPaterno,
          apellidoMaternoDemandado: demandaData.demandado.apellidoMaterno,
          pseudonimoDemandado: demandaData.demandado.pseudonimo,
        });
        setDemandadoTab(0);
      } else if (demandaData.demandado.tipoPersona === "moral") {
        setDemandadoMoral({
          nombreDemandadoMoral: demandaData.demandado.nombre,
        });
        setDemandadoTab(1);
      }

      setAnexos(demandaData.anexos || []);
    }
  }, [demandaData]);

  useEffect(() => {
    if (!isEditable) {
      onSave(() => handleButtonClick);
      setSaveLocalStorage(() => saveInStorage);
      setCerrarFuncion(() => handleClose);
    }

  }, [onSave, setSaveLocalStorage, anexos, actorTab, demandadoTab, actorFisica, actorMoral, demandadoFisica, demandadoMoral])


  useEffect(() => {

    const demandaRecuperada = JSON.parse(localStorage.getItem('formDemanda'));
    if (demandaRecuperada) {
      const {
        anexos,
        demandadoTab,
        actorTab,
        actorFisica,
        actorMoral,
        demandadoFisica,
        demandadoMoral,
      } = demandaRecuperada;

      setAnexos(anexos);
      setDemandadoTab(demandadoTab);
      setActorTab(actorTab);

      setActorFisica(actorTab === 0 ? actorFisica : initialActorFisica);
      setActorMoral(actorTab === 1 ? actorMoral : initialActorMoral);

      setDemandadoFisica(demandadoTab === 0 ? demandadoFisica : initialDemandadoFisica);
      setDemandadoMoral(demandadoTab === 1 ? demandadoMoral : initialDemandadoMoral);
    }
  }, [tipoDemandaSeleccionada]);

  //guardar en storage información: 
  const saveInStorage = () => {

    const data = {
      anexos: anexos,
      actorTab: actorTab,
      demandadoTab: demandadoTab,
      actorFisica: actorFisica,
      actorMoral: actorMoral,
      demandadoFisica: demandadoFisica,
      demandadoMoral: demandadoMoral
    }
    localStorage.setItem('formDemanda', JSON.stringify(data));
  }

  // Verifica si estamos en la ruta para poder editar
  const isEditRoute = location.pathname === "/api/workflow/demanda/edit";

  const isDemandaDataComplete = demandaData && demandaData.actor && demandaData.demandado;

  const handleDemandadoTabChange = (event, newValue) => {
    if (newValue !== null) {
      setDemandadoTab(newValue);
    }
  };
  const handleAddAnexo = () => {
    setAnexos([...anexos, ""]);

    setTimeout(() => {
      const lastAnexoRef = anexoRefs.current[anexos.length];
      if (lastAnexoRef) lastAnexoRef.focus();
    }, 0);
  };

  const handleAnexoChange = (index, value) => {
    const newValue = onlyAlphaNumericSpace(value);
    const newAnexos = [...anexos];
    newAnexos[index] = newValue;
    setAnexos(newAnexos);

    const newAnexosErrors = [...anexosErrors];
    newAnexosErrors[index] = value.length >= 3 ? "" : `El anexo ${index + 1} debe tener al menos 3 caracteres.`;
    setAnexosErrors(newAnexosErrors);
  };

  const fieldsMap = {
    nombreActor: onlyNames,
    apellidoPaternoActor: onlyNames,
    apellidoMaternoActor: onlyNames,
    pseudonimoActor: onlyNames,
    nombreActorMoral: allowSpecialCharacters,
    nombreDemandado: onlyNames,
    apellidoPaternoDemandado: onlyNames,
    apellidoMaternoDemandado: onlyNames,
    pseudonimoDemandado: onlyNames,
    nombreDemandadoMoral: allowSpecialCharacters,
  };

  const handleInputChange = (e, setStateFunction) => {
    let { name, value } = e.target;

    const caracterValue = (fieldsMap[name] || ((val) => val))(value);

    setStateFunction((prevState) => ({
      ...prevState,
      [name]: caracterValue,
    }));

    if (caracterValue.trim() !== "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: null,
      }));
    }
  };

  const validateForm = () => {
    const actorRules =
      actorTab === 0
        ? {
          nombreActor: { required: true },
          apellidoPaternoActor: { required: true },
        }
        : {
          nombreActorMoral: { required: true },
        };

    const demandadoRules =
      demandadoTab === 0
        ? {
          nombreDemandado: { required: true },
          apellidoPaternoDemandado: { required: true },
        }
        : {
          nombreDemandadoMoral: { required: true },
        };

    const actorErrors = validateFields(
      actorTab === 0 ? actorFisica : actorMoral,
      actorRules,
      showAlertValidate
    );
    const demandadoErrors = validateFields(
      demandadoTab === 0 ? demandadoFisica : demandadoMoral,
      demandadoRules,
      showAlertValidate
    );

    const edicionErrors = isEditRoute
      ? validateFields(
        { razonEdicion },
        { razonEdicion: { required: true } },
        showAlertValidate
      )
      : {};

    setErrors({
      ...actorErrors,
      ...demandadoErrors,
      ...edicionErrors,
    });

    return (
      Object.keys(actorErrors).length === 0 &&
      Object.keys(demandadoErrors).length === 0 &&
      Object.keys(edicionErrors).length === 0
    );
  };

  const handleSubmit = async () => {

    if (!validateForm()) return;

    const newAnexosErrors = anexos.map((anexo, index) =>
      anexo.length < 3 ? index + 1 : ""
    );
    setAnexosErrors(newAnexosErrors);

    const errors = newAnexosErrors.filter((error) => error !== "");
    if (errors.length === 1) {
      const errorMessage = `El anexo ${errors.join(
        ", "
      )} debe tener al menos 3 caracteres.`;
      showSnackbar(errorMessage, "error");
      return;
    } else if (errors.length > 0) {
      const errorMessage = `Los anexos ${errors.join(
        ", "
      )} deben tener al menos 3 caracteres.`;
      showSnackbar(errorMessage, "error");
      return;
    }

    setIsEditAnexos(false);
    setIsPreviewDemanda(true);
    setModalOpen(true);

  };

  const handleSubmitEdicion = () => {
    if (!validateForm()) return;

    setIsPreviewDemanda(true);
    setIsEditAnexos(true);
    setModalOpen(true);
  };

  const handleConfirmEditAnexos = () => {

    setModalOpen(false);
    handleEditDemanda(anexos, razonEdicion);

    navigate("/api/bandeja/entrada");
  };

  const handleButtonClick = (e) => {

    if (isEditRoute) {
      handleSubmitEdicion();
    } else {
      handleSubmit();
    }
  };

  const handleConfirm = async () => {
    const token = await getToken();

    // Transformar los datos al formato deseado
    const nuevoDocumento = {
      actor: crearActor(actorTab, actorFisica, actorMoral),
      demandado: crearDemandado(demandadoTab, demandadoFisica, demandadoMoral),
      anexos: anexos,
      tipoJuicioId: tipoDemandaSeleccionada.juicioId,
    };

    try {
      let documento = await addDocumento(token, nuevoDocumento);

      navigate("/api/workflow/demanda");
      showSnackbar("Demanda generada con éxito", "success");
      const sello = await getSello(token, documento.id);
      var blob = new Blob([sello], { type: "application/pdf" });
      let link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.target = "sello.pdf";
      link.click();
      localStorage.removeItem("formDemanda");
    } catch (error) {
      if (error.response && error.response.data[0]) {
        showSnackbar(error.response.data[0].message, "error");
      } else {
        showSnackbar("¡Algo salió mal!", "error");
      }
    }

    setModalOpen(false);
  };

  const handleClose = () => {

    if (isEditRoute) {
      navigate("/api/bandeja/entrada");
    } else {
      navigate("/api/workflow/demanda");
    }
    localStorage.removeItem('formDemanda');
  };

  const handleRemoveAnexo = (index) => {
    const newAnexos = anexos.filter((_, i) => i !== index);
    setAnexos(newAnexos);
  };

  const formulario = () => {
    return <>


      <Grid className="box-sombra">

        {isEditable && (<Breadcrumbs istitulo="true" className="title2" />)}


        {!isEditable && (selectCambioDemanda?.())}

        <form onSubmit={handleSubmit} disabled>
          <Grid sx={{ mt: 4 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={1}>
                <Typography>Actor: </Typography>
              </Grid>

              <Grid item xs={4} mb={2} ml={7}>
                <ToggleButtonGroup
                  value={actorTab}
                  color="primary"
                  disabled={isPreviewDemanda}
                  exclusive
                  onChange={handleActorToggleChange}
                  fullWidth
                >
                  <ToggleButton value={0} sx={{ textTransform: 'none' }}>Persona física</ToggleButton>
                  <ToggleButton value={1} sx={{ textTransform: 'none' }}>Persona moral</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            {/* FORM ACTOR */}
            <Actor
              actorTab={actorTab}
              actorFisica={actorFisica}
              actorMoral={actorMoral}
              handleInputChange={handleInputChange}
              setActorFisica={setActorFisica}
              setActorMoral={setActorMoral}
              errors={errors}
              isEditable={isEditable || isPreviewDemanda}
            />
            {/* FORM DEMANDADO */}
            <Divider sx={{ my: 5 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={1}>
                <Typography>Demandado: </Typography>
              </Grid>

              <Grid item xs={4} mb={2} ml={7}>
                <ToggleButtonGroup
                  value={demandadoTab}
                  color="primary"
                  exclusive
                  onChange={handleDemandadoTabChange}
                  disabled={isPreviewDemanda}
                  fullWidth
                >
                  <ToggleButton value={0} sx={{ textTransform: 'none' }}>Persona física</ToggleButton>
                  <ToggleButton value={1} sx={{ textTransform: 'none' }}>Persona moral</ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>

            <Demandado
              demandadoTab={demandadoTab}
              demandadoFisica={demandadoFisica}
              demandadoMoral={demandadoMoral}
              handleInputChange={handleInputChange}
              setDemandadoFisica={setDemandadoFisica}
              setDemandadoMoral={setDemandadoMoral}
              errors={errors}
              isEditable={isEditable || isPreviewDemanda}
            />

            {/* MOTIVO EDICIÓN */}

            {isEditRoute && isDemandaDataComplete && (
              <Grid item xs={12}>
                <Divider sx={{ my: 5 }} />
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={1}>
                    <Typography>Motivo edición: </Typography>
                  </Grid>

                  <Grid item xs={10} mb={2} ml={7}>
                    <TextField
                      multiline
                      placeholder="Motivo edición"
                      fullWidth
                      disabled={isPreviewDemanda}
                      variant="outlined"
                      value={razonEdicion}
                      onChange={(e) => {
                        setRazonEdicion(e.target.value);

                        if (errors.razonEdicion) {
                          setErrors((prevErrors) => ({
                            ...prevErrors,
                            razonEdicion: "",
                          }));
                        }
                      }}
                      error={!!errors.razonEdicion}
                      helperText={
                        <Box display="flex" justifyContent="space-between">
                          <span>{errors.razonEdicion}</span>
                          <span>{`${razonEdicion.length}/150 caracteres`}</span>
                        </Box>
                      }
                      inputProps={{ maxLength: 150 }}
                      required={isEditRoute}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}

            <Divider sx={{ my: 5 }} />
            {/*  */}

            {/* ANEXOS */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={1} mt={2}>
                <Typography>Anexos: </Typography>
              </Grid>

              <Anexo
                anexos={anexos}
                handleAnexoChange={handleAnexoChange}
                handleRemoveAnexo={handleRemoveAnexo}
                handleAddAnexo={handleAddAnexo}
                anexosErrors={anexosErrors}
                anexoRefs={anexoRefs}
                isPreviewDemanda={isPreviewDemanda}
              />


            </Grid>
          </Grid>

        </form>

      </Grid>
    </>
  }



  return (
    <>

      {isEditable && (<Acciones accion1={handleButtonClick} accion2={handleClose}></Acciones>)}

      <ConfirmationModal
        open={modalOpen && !isEditAnexos}
        onClose={() => { setModalOpen(false); setIsPreviewDemanda(false); }}
        onConfirm={handleConfirm}
        title="¿Generar demanda?"
        message="Se generará una nueva demanda en el sistema."
        confirmationButton="Sí, generar"
        closeButton="No, cancelar"
        formulario={formulario}
      />

      <ConfirmationModal
        open={modalOpen && isEditAnexos}
        onClose={() => setModalOpen(false)}
        onConfirm={handleConfirmEditAnexos}
        title="¿Actualizar anexos?"
        message="Se actualizarán los anexos con el motivo de la edición en el sistema."
        confirmationButton="Sí, actualizar"
        closeButton="No, cancelar"
        formulario={formulario}
      />

      {formulario()}
    </>
  );
}
