import { Box, Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
import Glosario from "../../../assets/Glosario";
import { hasRole } from "../../../shared/Util";
import ConfirmationDialog from "../../../components/Alert/ConfirmationDialog/ConfirmationDialogGeneric";
import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../../api/keycloakConf";
import { cambioEstatusBandejaEnviados, digitalizarAcuseOficioOCP, obtenerAcuseDocumentoOCP } from "../BandejaEnviosService";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import ModalCambioEstatusEnvios from "./ModalCambioEstatusEnvios";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ModalDigitalizacionOCP from "./ModalDigitalizacionOCP";
import DownloadIcon from '@mui/icons-material/Download';
import SendIcon from '@mui/icons-material/Send';
import ApprovalIcon from "@mui/icons-material/Approval";
import { obtenerOficioCargado } from "../../Oficios/RegistroOficiosService";
const TableBandejaEnvios = ({
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    bandejaEnvios,
    loadBandejaEnvios,
    mensajeros
}) => {

    const [documentoId, setDocumentoId] = useState();
    const [modalCambioEstatus, setModalCambioEstatus] = useState(false);
    const [openModalDigitalizacion, setOpenModalDigitalizacion] = useState(false);
    const [estatusSeleccionado, setEstatusSeleccionado] = useState(null);
    const [mensajeroSeleccionado, setMensajeroSeleccionado] = useState(null);
    const [estatusDisponibles, setEstatusDisponibles] = useState([]);
    const { getToken } = useContext(keycloakConf);
    const { showSnackbar } = useToastAlert();
    const [modalCambioEstatusEnviosOpen, setModalCambioEstatusEnviosOpen] = useState(false);
    const [fileDigitalizacion, setFileDigitalizacion] = useState();

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        "&:nth-of-type(odd)": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:last-child td, &:last-child th": {
            border: 0,
        },
    }));
    const estatusGenerales = [
        { id: 1, value: "ENVIADO", nombre: "Enviado a la OCP" },
        { id: 2, value: "RECIBIDO", nombre: "Recibido en la OCP" },
        { id: 3, value: "ENVIADO_DESTINO", nombre: "Enviado al destino" },
        { id: 4, value: "RECIBIDO_DESTINO", nombre: "Recibido en el destino" }
    ]

    useEffect(() => {
        const isOficialMayorJuzgado = hasRole("OFICIAL_MAYOR_JUZGADO") || hasRole("AUXILIAR_OFICIAL_MAYOR_JUZGADO");

        if (isOficialMayorJuzgado) {
            setEstatusDisponibles([estatusGenerales.find(estatus => estatus.value === "ENVIADO")]);
            return;
        }

    }, []);

    const handleCambioEstatusOCP = (item) => {
        console.log(item);

        //Obtenemos el estatus del registro seleccionado y basado en eso filtramos solo los estatus posteriores para evitar que camie a un estatus inferior.  
        const estatusRegistro = estatusGenerales.find(estatus => estatus.nombre === item.estatusEnvio);
        console.log(estatusRegistro);

        const estatusDisponiblesPorRegistro = estatusGenerales.filter(estatus => estatus.id > estatusRegistro.id);
        setEstatusDisponibles(estatusDisponiblesPorRegistro)

        setModalCambioEstatusEnviosOpen(true);
        setDocumentoId(item.documentoId);
    };

    const handleConfirmCambioEstatusOCP = async () => {
        const token = getToken();
        const data = {
            oficiosIds: [documentoId],
            estadoEnvio: estatusSeleccionado,
            mensajero: mensajeroSeleccionado ? mensajeroSeleccionado : null
        }

        try {
            const response = await cambioEstatusBandejaEnviados(token, data);

            if (response.estatus === 200) {
                showSnackbar("Se ha cambiado el estatus correctamente", "success");
                loadBandejaEnvios();
            } else {
                showSnackbar("Ha ocurrido un error al actualizar el estatus", "error");
            }
        } catch (error) {
            console.log("Ha ocurrido un error al cambiar el estatus");
            showSnackbar("Ha ocurrido un error al actualizar el estatus", "error");
        }
    }

    const handleConfirmCambioEstatus = (item) => {
        setModalCambioEstatus(true);
        setDocumentoId(item.documentoId);
    }

    const handleDigitalizarOficioOCP = async (file) => {
        const formData = new FormData();
        formData.append("documentoId", documentoId);
        formData.append("file", file);

        try {
            const response = await digitalizarAcuseOficioOCP(getToken(), formData);
            if (response.estatus === 200) {
                showSnackbar("Se ha digitalizado el acuse correctamente", "success");
                loadBandejaEnvios();

            } else {
                showSnackbar("Ha ocurrido un error al digitalizar el documento.", "error")
            }
        } catch (error) {
            showSnackbar("Ha ocurrido un error al digitalizar el documento.", "error")
        }



    }

    const handleCambioEstatusJuzgados = async () => {
        const data = {
            oficiosIds: [documentoId],
            estadoEnvio: 'ENVIADO',
            mensajero: null
        }
        const token = getToken();
        try {
            const response = await cambioEstatusBandejaEnviados(token, data);
            if (response.estatus === 200) {
                showSnackbar("Se ha cambiado el estatus correctamente", "success");
                loadBandejaEnvios();
            } else {
                showSnackbar("Ha ocurrido un error al actualizar el estatus", "error");
            }
        } catch (error) {
            console.log("Ha ocurrido un error al cambiar el estatus");
            showSnackbar("Ha ocurrido un error al actualizar el estatus", "error");
        }
    }

    const handleDescargarAcuseOficioOCP = async (documentoId) => {
        try {
            const response = await obtenerAcuseDocumentoOCP(getToken(), documentoId);

            let blob = new Blob([response], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.target = "acuseOficioOCP";
            link.click();
            showSnackbar("Se ha descargado el documento correctamente", "success");
        } catch (error) {
            showSnackbar("Ha ocurrido un error al descargar el documento", "error");
        }


    }

    const handleShowOficio = async (item) => {

        try {
            const token = getToken();

            let documento = await obtenerOficioCargado(token, item.documentoId);

            let blob = new Blob([documento], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.target = "oficio.pdf";
            link.click();

        } catch (error) {
            console.error("Error al generar el documento:", error);
            showSnackbar("¡Algo salió mal!", "error");
        }
    };

    return <>
        <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
                <Paper sx={{ width: "100%", mb: 2 }}>
                    <TableContainer sx={{ maxHeight: 600, overflowY: "auto", mt: 2 }}>
                        <PaginationGeneric
                            pageable={pageable}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                        <Table stickyHeader sx={{ minWidth: 750 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className="table-header" align="center">{Glosario.Folio}</TableCell>
                                    <TableCell className="table-header" align="center">{Glosario.Destino}</TableCell>
                                    <TableCell className="table-header" align="center">{Glosario.Origen}</TableCell>
                                    <TableCell className="table-header" align="center">{Glosario.Mensajero}</TableCell>
                                    <TableCell className="table-header" align="center">{Glosario.Estatus}</TableCell>
                                    <TableCell className="table-header" align="center">{Glosario.Acciones}</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>

                                {bandejaEnvios.map((item) => {

                                    return (
                                        <StyledTableRow
                                            key={item.documentoId}
                                            hover
                                        >
                                            <TableCell align="center">
                                                {item.documentoId}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.destino}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.origen}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.mensajero}
                                            </TableCell>
                                            <TableCell align="center">
                                                {item.estatusEnvio ? item.estatusEnvio : item.estatusOficio}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Tooltip title="Oficio cargado">
                                                    <ApprovalIcon
                                                        style={{ marginRight: '10px' }}
                                                        className="icon-color"
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            handleShowOficio(item);

                                                        }}
                                                    />
                                                </Tooltip>
                                                {
                                                    ((hasRole("OFICIAL_MAYOR_OFICIALIA") && (item.estatusEnvio !== "Recibido en el destino" && item.estatusEnvio !== "Digitalizado"))
                                                        || ((hasRole("OFICIAL_MAYOR_JUZGADO") || hasRole("AUXILIAR_OFICIAL_MAYOR_JUZGADO")) && item.estatusEnvio === ""))
                                                    && (
                                                        <Tooltip title="Cambiar estatus">
                                                            <SendIcon
                                                                className="icon-color"
                                                                onClick={() => {
                                                                    hasRole("OFICIAL_MAYOR_OFICIALIA") ? handleCambioEstatusOCP(item) : handleConfirmCambioEstatus(item);
                                                                }
                                                                }
                                                            />
                                                        </Tooltip>
                                                    )}

                                                {item.estatusEnvio === "Recibido en el destino" && hasRole("OFICIAL_MAYOR_OFICIALIA")
                                                    && (
                                                        <Tooltip title="Digitalización">
                                                            <PictureAsPdfIcon
                                                                className="icon-color"
                                                                onClick={() => { setOpenModalDigitalizacion(true); setDocumentoId(item.documentoId); }}
                                                            />
                                                        </Tooltip>
                                                    )}

                                                {item.estatusEnvio === "Digitalizado" && (
                                                    <Tooltip title="Descargar acuse">
                                                        <DownloadIcon
                                                            className="icon-color"
                                                            onClick={() => { handleDescargarAcuseOficioOCP(item.documentoId) }}
                                                        />
                                                    </Tooltip>
                                                )}

                                            </TableCell>
                                        </StyledTableRow>
                                    )
                                })}

                                {bandejaEnvios.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={8} align="center">
                                            No hay registros disponibles.
                                        </TableCell>
                                    </TableRow>
                                )}

                            </TableBody>
                        </Table>
                        <PaginationGeneric
                            pageable={pageable}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                        />
                    </TableContainer>
                </Paper>
            </Box>

        </Grid>

        <ConfirmationDialog
            open={modalCambioEstatus}
            onClose={() => setModalCambioEstatus(false)}
            onConfirm={handleCambioEstatusJuzgados}
            title={"Enviar oficio a la OCP"}
            message={"¿ Esta usted seguro de enviar este oficio a la OCP ?"}
            btnLeft={"Cancelar"}
            btnRight={"Aceptar"}

        />

        <ModalCambioEstatusEnvios
            open={modalCambioEstatusEnviosOpen}
            onClose={() => {
                setModalCambioEstatusEnviosOpen(false);
                setEstatusSeleccionado(null);
            }}
            onConfirm={handleConfirmCambioEstatusOCP}
            mensajeros={mensajeros}
            estatusDisponibles={estatusDisponibles}
            estatusSeleccionado={estatusSeleccionado}
            setEstatusSeleccionado={setEstatusSeleccionado}
            mensajeroSeleccionado={mensajeroSeleccionado}
            setMensajeroSeleccionado={setMensajeroSeleccionado}
        />

        <ModalDigitalizacionOCP
            open={openModalDigitalizacion}
            onClose={() => {
                setOpenModalDigitalizacion(false);
            }}
            onConfirm={(file) => { handleDigitalizarOficioOCP(file); }}
            setFileDigitalizacion={setFileDigitalizacion}
        />


    </>
}


export default TableBandejaEnvios;