import React from 'react';
import { Grid, TextField } from '@mui/material';
import { onlyText, allowSpecialCharacters } from '../../../../components/Caracteres/caracteresEspeciales';

const Demandado = ({
  demandadoTab,
  demandadoFisica,
  demandadoMoral,
  handleInputChange,
  setDemandadoFisica,
  setDemandadoMoral,
  errors,
  isEditable,
  materia
}) => {

  const handleFilteredInputChange = (event, setDemandadoState, fieldName, isPersonaMoral=false) => {
    const { value } = event.target;
    const filteredValue = isPersonaMoral ? allowSpecialCharacters(value) : onlyText(value); 
    handleInputChange(
      { target: { name: fieldName, value: filteredValue } },
      setDemandadoState,
      fieldName
    );
  };

  return (
    <Grid container spacing={2} mt={1}>
      <Grid sm={1}></Grid>
      <Grid ml={7} item xs={10}>
        {demandadoTab === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Nombre(s)"
                name="nombreDemandado"
                fullWidth
                value={demandadoFisica.nombreDemandado}
                onChange={(e) =>
                  handleFilteredInputChange(e, setDemandadoFisica, 'nombreDemandado')
                }
                error={!!errors.nombreDemandado}
                helperText={errors.nombreDemandado}
                disabled={isEditable}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Apellido paterno"
                name="apellidoPaternoDemandado"
                fullWidth
                value={demandadoFisica.apellidoPaternoDemandado}
                onChange={(e) =>
                  handleFilteredInputChange(e, setDemandadoFisica, 'apellidoPaternoDemandado')
                }
                error={!!errors.apellidoPaternoDemandado}
                helperText={errors.apellidoPaternoDemandado}
                disabled={isEditable}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Apellido materno"
                name="apellidoMaternoDemandado"
                fullWidth
                value={demandadoFisica.apellidoMaternoDemandado}
                onChange={(e) =>
                  handleFilteredInputChange(e, setDemandadoFisica, 'apellidoMaternoDemandado')
                }
                disabled={isEditable}
              />
            </Grid>
            
          </Grid>
        )}

        {demandadoTab === 1 && (
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              name="nombreDemandadoMoral"
              fullWidth
              value={demandadoMoral.nombreDemandadoMoral}
              onChange={(e) =>
                handleFilteredInputChange(e, setDemandadoMoral, 'nombreDemandadoMoral', true)
              }
              error={!!errors.nombreDemandadoMoral}
              helperText={errors.nombreDemandadoMoral}
              disabled={isEditable}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Demandado;
