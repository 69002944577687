import {  Button,  Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";

const ModalCambioEstatusEnvios = ({
    open, onClose, onConfirm,
    mensajeros, estatusDisponibles, estatusSeleccionado,
    setEstatusSeleccionado, mensajeroSeleccionado, setMensajeroSeleccionado }) => {
    const dialogTitleStyle = { textAlign: 'left', fontWeight: 'bold', fontSize: '22px', color: '#000000B3' };
    const dialogActionsStyle = { justifyContent: 'right' };
    const buttonStyle = { textTransform: 'none' };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            PaperProps={{
                style: {
                    width: '700px',
                    maxWidth: '90vw',
                    height: 'auto',
                    padding: '5px',
                    position: 'fixed'

                },
            }}
            disableEscapeKeyDown
        >

            <DialogTitle style={dialogTitleStyle}>Cambio de estatus</DialogTitle>
            <DialogContent >

                <Grid container spacing={2} rowSpacing={2} mt={1}>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="estatus-label" style={{ display: "block" }}>Estatus</InputLabel>
                            <Select
                                labelId="estatus-label"
                                label="Estatus"
                                id="estatus-select"
                                value={estatusSeleccionado}
                                onChange={(e) => setEstatusSeleccionado(e.target.value)}
                            >
                                {estatusDisponibles.map((option) => (
                                    <MenuItem key={option.value} value={option.value}>
                                        {option.nombre}
                                    </MenuItem>
                                ))}
                            </Select>
                            
                        </FormControl>
                    </Grid>
                    {(estatusSeleccionado === "ENVIADO_DESTINO" || estatusSeleccionado === "RECIBIDO_DESTINO") && (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel id="mensajero-label">Mensajeros</InputLabel>
                                <Select
                                    labelId="mensajero-label"
                                    id="mensajero-select"
                                    label="Mensajeros"
                                    value={mensajeroSeleccionado}
                                    onChange={(e) => setMensajeroSeleccionado(e.target.value)}
                                >
                                    {mensajeros.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.nombre}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}
                </Grid>

            </DialogContent>

            <DialogActions style={dialogActionsStyle}>
                <Button
                    variant="outlined"
                    className="button-close"
                    onClick={onClose}
                    style={{ ...buttonStyle, borderColor: '#636569', color: '#fff' }}
                >
                    Cancelar
                </Button>
                <Button
                    variant="outlined"
                    onClick={() => {
                        onConfirm();
                        onClose();
                    }}
                    className="button-save"
                    style={{ ...buttonStyle, borderColor: '#8c92bc', color: '#fff' }}
                >
                    Actualizar estatus
                </Button>
            </DialogActions>

        </Dialog >
    );
}

export default ModalCambioEstatusEnvios;