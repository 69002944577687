import axios from 'axios';

export const fetchRoles = async (token) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/roles`, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error al listar roles:', error);
    throw error; 
  }
};

export const fetchRolesByUserId = async (token, userId, tipoCentroTrabajo, isEdicion, id) => {
  try {
    const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL +  `/api/core/roles/${userId}/${tipoCentroTrabajo}/${isEdicion}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error al obtener el registro:', error);
    throw error;
  }
};