import React from 'react';

const Home = () => {




  return (

    <div>
    <h1>Bienvenido</h1>
  </div>
  );
};

export default Home;
