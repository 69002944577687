import React, { useState, useRef } from "react";
import {
  TextField,
  Divider,
  IconButton,
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  Typography,
  Grid,
  Button,
  Box,
  InputLabel,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { onlyAlphaNumericSpace } from "../../../components/Caracteres/caracteresEspeciales";
import DeleteIcon from "@mui/icons-material/Delete";
import DigitalizarDemanda from "../../RegistrosAntiguos/DigitalizarDemanda";

export default function FormPromocion({
  carpeta,
  tipoPromocion,
  setTipoPromocion,
  errorMessages,
  anexos,
  setAnexos,
  onFileSelect,
  isOficialia  
}) {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const anexoRefs = useRef([]);
  const [anexosErrors, setAnexosErrors] = useState([]);

  const handleAddAnexo = () => {
    setAnexos([...anexos, ""]);
    setIsButtonClicked(true);

    setTimeout(() => {
      const lastAnexoRef = anexoRefs.current[anexos.length];
      if (lastAnexoRef) lastAnexoRef.focus();
    }, 0);
  };

  const handleAnexoChange = (index, value) => {
    const newValue = onlyAlphaNumericSpace(value);
    const newAnexos = [...anexos];
    newAnexos[index] = newValue;
    setAnexos(newAnexos);

    const newAnexosErrors = [...anexosErrors];
    newAnexosErrors[index] = newValue.length < 3 ? `El anexo debe tener al menos 3 caracteres.` : "";
    setAnexosErrors(newAnexosErrors);
  };

  const handleRemoveAnexo = (index) => {
    const newAnexos = anexos.filter((_, i) => i !== index);
    setAnexos(newAnexos);

    if (newAnexos.length === 0) {
      setIsButtonClicked(false);
    }
  };

  const handleChange = (event) => {
    setTipoPromocion({ tipoPromocion: event.target.value });
  };

  return (
    <div>
      <form>
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6} md={2}>
            <Typography>Actor: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={10}>
            <Typography style={{ color: "gray" }}>
              {carpeta.actor}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography>Demandado: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={10}>
            <Typography style={{ color: "gray" }}>
              {carpeta.demandado || ""}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Divider />
        </Box>

        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography sx={{ minWidth: "150px" }}>
                Tipo promoción
              </Typography>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errorMessages.tipoPromocion}
              >
                <InputLabel id="select-tipo-promocion-label"></InputLabel>
                <Select
                  labelId="select-tipo-promocion-label"
                  name="tipoPromocion"
                  value={tipoPromocion.tipoPromocion}
                  onChange={handleChange}
                  label=""
                  error={!!errorMessages.tipoPromocion}
                  displayEmpty
                  renderValue={(selected) => {
                    if (!selected) {
                      return (
                        <span style={{ color: "gray" }}>Selecciona uno</span>
                      );
                    }
                    return selected === "ESCRITO" ? "Escrito" : "Oficio";
                  }}
                >
                  <MenuItem value="ESCRITO">Escrito</MenuItem>
                  <MenuItem value="OFICIO">Oficio</MenuItem>
                </Select>
                {errorMessages.tipoPromocion && (
                  <FormHelperText>{errorMessages.tipoPromocion}</FormHelperText>
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Typography sx={{ minWidth: "150px" }}>
                Anexos
              </Typography>
              <Box sx={{ flexGrow: 2 }}>
                {anexos.map((anexo, index) => (
                  <Grid
                    container
                    key={index}
                    sx={{ mb: 1 }}
                    alignItems="center"
                  >
                    <Grid item xs={11}>
                      <TextField
                        label={`Anexo ${index + 1}`}
                        fullWidth
                        value={anexo}
                        onChange={(e) => handleAnexoChange(index, e.target.value)}
                        inputRef={(el) => (anexoRefs.current[index] = el)}
                        helperText={anexosErrors[index] && "El anexo debe tener al menos 3 caracteres"}
                        FormHelperTextProps={{
                          style: {
                            color: anexosErrors[index] ? "#d32f2f" : "inherit",
                          },
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <IconButton
                        onClick={() => handleRemoveAnexo(index)}
                      >
                        <DeleteIcon className='icon-color' />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}

                {/* Button anexos */}
                <Grid item xs={12} mb={3}>
                  <Button
                    className="button-validation"
                    startIcon={<AddCircleOutlineIcon />}
                    variant="contained"
                    onClick={handleAddAnexo}
                  >
                    Agregar anexo
                  </Button>
                </Grid>
              </Box>
            </Box>
            {!isOficialia && (
            <Grid container spacing={12} >
              <Grid item xs={12} sm={1} mt={2}>Documento expediente: </Grid>
              <DigitalizarDemanda onFileSelect={onFileSelect} />
            </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
