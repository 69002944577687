import React, { useContext, useState } from 'react';
import { Box, Checkbox, Grid, IconButton, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import PaginationGeneric from "../../../components/Pagination/PaginationGeneric";
import AddTaskIcon from '@mui/icons-material/AddTask';
import UndoIcon from '@mui/icons-material/Undo';
import text from '../../../assets/Glosario';
import ReturnTurnadoModal from "../../../components/Modal/ReturnTurnadoModal";
import RecepcionModal from '../../../components/ModalPersonalJuzgado/RecepcionModal';
import Breadcrumbs from '../../../components/Breadcrumb/breadcrumbs';
import { useToastAlert } from '../../../components/Alert/ToastAlertSave/ToastAlertSave';
import RefreshButton from '../../../components/Button/RefreshButton';
import RecepcionMultipleModal from '../../../components/Modal/RecepcionMultipleModal';
import Recepcion from './Recepcion';
import { fetchItemById } from './BandejaService';
import keycloakConf from '../../../api/keycloakConf';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: '#8c92bc', // Color de fondo del botón
  color: 'white', // Color del ícono
  borderRadius: '50%', // Hace que sea circular
  '&:hover': {
    backgroundColor: '#afb3d0', // Color al pasar el mouse
  },
}));

const RecepcionTable = ({
  bandejas,
  pageable,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  handleRefresh,
  setBandejas
}) => {
  const { showSnackbar } = useToastAlert();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedExpediente, setSelectedExpediente] = useState(null);
  const [isRecepcionModalOpen, setIsRecepcionModalOpen] = useState(false);
  const [recepcionItem, setRecepcionItem] = useState(null);
  const [isRecepcionMultiOpen, setIsRecepcionMultiOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const { getToken } = useContext(keycloakConf);
  const [modalAnotaciones, setModalAnotaciones] = useState(false);
  const [dataRecepcion, setDataRecepcion] = useState();


  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems([...bandejas]);
    } else {
      setSelectedItems([]);
    }
  };

  const isItemSelected = (item) =>
    selectedItems.some((s) => s.id === item.id && s.tipoEntrada === item.tipoEntrada);

  const handleSelectRow = (item) => {
    const isSelected = isItemSelected(item);
    setSelectedItems((prev) =>
      isSelected
        ? prev.filter((s) => !(s.id === item.id && s.tipoEntrada === item.tipoEntrada))
        : [...prev, item]
    );
  };

  const addToRecepcion = () => {
    if (selectedItems.length === 0) {
      showSnackbar('Seleccione al menos un registro', 'error');
      return;
    }

    for (let item of selectedItems) {

      if (!item.hasOwnProperty("observaciones") && !item.hasOwnProperty("recomendaciones") && !item.hasOwnProperty("anexos") && !item.isInterno) {
        showSnackbar(`La entrada con folio ${item.folio} no ha sido validada.`, 'error');
        return;
      }

      if (item.isInterno && !item.hasOwnProperty("idConcepto") && !item.hasOwnProperty("idDocumentoRecepcion")) {
        showSnackbar(`La entrada con folio ${item.folio} no ha sido validada.`, 'error');
        return;
      }
    }

    setIsRecepcionMultiOpen(true);
  };

  const handleRemoveFromCart = (id) => {
    const updated = selectedItems.filter((item) => item.id !== id);
    setSelectedItems(updated);
  };

  const handleShowModalAnotaciones = async (item) => {

    try {
      let data = await fetchItemById(getToken(), item.id);
      data.id = item.id;
      data.observaciones = item.observaciones;
      data.recomendaciones = item.recomendaciones;
      data.anexos = item.anexos ? item.anexos : data.anexos;

      setDataRecepcion(data);
      setModalAnotaciones(true);


    } catch (error) {
      console.error("Error al cargar los datos:", error);
    }


  }

  const handleSaveTemporal = (data) => {

    const bandejasNew = bandejas.map(item => {

      if (item.id === data.id && !item.isInterno) {
        item['anexos'] = data.anexos;
        item['observaciones'] = data.observaciones;
        item['recomendaciones'] = data.recomendaciones;
      }

      if (item.id === data.idDocumentoRecepcion && item.isInterno) {
        item['idDocumentoRecepcion'] = data.idDocumentoRecepcion;
        item['idConcepto'] = data.idConcepto;
        item['conceptoSelected'] = data.conceptoSelected;
      }
      return item;
    });

    setBandejas(bandejasNew);

    setIsRecepcionModalOpen(false);
    setModalAnotaciones(false);
    showSnackbar("Anotaciones actualizadas.", "success");

  }

  //funcion handle concepto:
  const handleShowModalWithConcepto = (item) => {
    setRecepcionItem(item);
    setIsRecepcionModalOpen(true);
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Grid className="box-sombra">
        <Box mb={2} display="flex" alignItems="center">
          <Breadcrumbs istitulo="true" className="title2" />
          <Box display="flex" alignItems="center" sx={{ marginLeft: 2 }}>
            <RefreshButton onClick={handleRefresh} />
          </Box>
          <Box display="flex" alignItems="center" sx={{ ml: 1 }}>
            <Tooltip title="Turnar">
              <StyledButton onClick={addToRecepcion}>
                <AddTaskIcon />
              </StyledButton>
            </Tooltip>
          </Box>
        </Box>

        <Paper sx={{ width: '100%', mb: 2 }}>
          <PaginationGeneric
            pageable={pageable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
          <TableContainer sx={{ maxHeight: 600 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="table-header" padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedItems.length > 0 && selectedItems.length < bandejas.length
                      }
                      checked={bandejas.length > 0 && selectedItems.length === bandejas.length}
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell className="table-header">{text.Folio}</TableCell>
                  <TableCell className="table-header">{text.NoExpediente}</TableCell>
                  <TableCell className="table-header">{text.TipoEntrada}</TableCell>
                  <TableCell className="table-header">{text.Origen}</TableCell>
                  <TableCell className="table-header">{text.Concepto}</TableCell>
                  <TableCell className="table-header">{text.FechaHoraEnvio}</TableCell>
                  <TableCell className="table-header">{text.Acciones}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {bandejas.map((item) => (
                  <StyledTableRow
                    key={item.id}
                    hover
                    selected={isItemSelected(item)}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={isItemSelected(item)}
                        onClick={(e) => e.stopPropagation()}
                        onChange={() => handleSelectRow(item)}
                      />
                    </TableCell>
                    <TableCell>{item.folio}</TableCell>
                    <TableCell>{item.expediente}</TableCell>
                    <TableCell>{item.tipoEntrada === 'Promocion' ? 'Promoción' : item.tipoEntrada}</TableCell>
                    <TableCell>{item.origen}</TableCell>
                    <TableCell>{item.concepto}</TableCell>
                    <TableCell>{new Date(item.fechaHoraEnvio).toLocaleString()}</TableCell>
                    <TableCell>
                      <Tooltip title="Devolver">
                        <UndoIcon
                          style={{ marginRight: '10px' }}
                          className="icon-color"
                          onClick={
                            () => {
                              setSelectedExpediente({ expediente: item.expediente, documentoId: item.id, isInterno: item.isInterno });
                              setIsModalOpen(true)
                            }

                          }
                        />
                      </Tooltip>

                      <Tooltip title="Anotaciones">
                        <AddTaskIcon
                          style={{ marginRight: '10px' }}
                          className="icon-color"
                          onClick={() =>
                            item.isInterno
                              ? handleShowModalWithConcepto(item)
                              : handleShowModalAnotaciones(item)
                          }

                        />
                      </Tooltip>
                    </TableCell>
                  </StyledTableRow>
                ))}
                {bandejas.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={8} align="center">
                      No hay registros disponibles.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <PaginationGeneric
            pageable={pageable}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </Paper>

        {/* Modales */}

        {  /*  MODAL PARA REGRESAR YA SEA A OFICIALIA O A RL ORIGEN.  */}
        <ReturnTurnadoModal
          isOpen={isModalOpen}
          handleClose={() => setIsModalOpen(false)}
          expediente={selectedExpediente}
          handleSave={() => setIsModalOpen(false)}
          handleRefresh={handleRefresh}
        />

        {  /* MODAL DE RECEPCIÓN CUANDO ES INTERNO: */}
        <RecepcionModal
          open={isRecepcionModalOpen}
          onClose={() => setIsRecepcionModalOpen(false)}
          onSave={(data) => { handleSaveTemporal(data); }}
          item={recepcionItem}
          handleRefresh={handleRefresh}
        />

        {  /* MODAL DE RECEPCIÓN CUANDO NO ES INTERNO */}
        <Recepcion
          item={recepcionItem}
          onOpen={modalAnotaciones}
          onClose={() => { setModalAnotaciones(false) }}
          onSave={(data) => { handleSaveTemporal(data); }}
          data={dataRecepcion}
        />

        { /*  MODAL PARA CONFIRMAR LOS REGISTROS SELECCIONADOS Y A ENVIAR. */}
        <RecepcionMultipleModal
          isOpen={isRecepcionMultiOpen}
          items={selectedItems}
          isInterno={false}
          handleClose={() => { setIsRecepcionMultiOpen(false); handleRefresh(); }}
          onRemove={handleRemoveFromCart}
        />
      </Grid>
    </Box>
  );
};

export default RecepcionTable;