import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Acciones from "../../../components/Acciones/Acciones";
import { Autocomplete, Button, Grid, TextField } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import FormDemanda from "./FormDemanda";
import FormDemandaFamiliar from "./FormFamiliar/FormDemandaFamiliar";
import FormDemandaPenal from "./FormPenal/FormDemandaPenal";

export default function FormDemandaPadre() {
    const location = useLocation();
    const navigate = useNavigate();
    const { datos, nombreJuicio, materia, juicioId } = location.state || {};
    const [tipoDemandaSeleccionada, setTipoDemandaSeleccionada] = useState();
    const [tiposDemandas, setTiposDemandas] = useState([]);
    const [guardarFuncion, setGuardarFuncion] = useState(() => () => { });
    const [enableChangeTipoDemanda, setEnableChangeTipoDemanda] = useState(true);
    const [saveDemandaLocalStorage, setSaveLocalStorage] = useState(() => {});
    const [cerrarFuncion, setCerrarFuncion] = useState(() => () => {});

    useEffect(() => {
        mapTiposJucioGroupByMaterias();
    }, [])

    useEffect(() => {
        if (tipoDemandaSeleccionada) {
            renderComponenteDemanda();
        }
    }, [tipoDemandaSeleccionada]);


    const mapTiposJucioGroupByMaterias = () => {
        let nuevoArray = datos.flatMap(item =>
            item.juicios.map(juicio => ({
                juicioId: juicio.id,
                nombreJuicio: juicio.nombre,
                materiaId: juicio.materiaRecord.id,
                nombreMateria: juicio.materiaRecord.nombre,
                tipoSistemaId: juicio.tipoSistemaRecord.id,
                nombreTipoSistema: juicio.tipoSistemaRecord.nombre
            }))
        );

        const tipoJuicioSeleccionado = nuevoArray.find(item => item.juicioId === juicioId);

        setTipoDemandaSeleccionada(tipoJuicioSeleccionado);
        setTiposDemandas(nuevoArray)
    }

    // Función para manejar el botón "Guardar"
    const handleButtonClick = () => {
        if (guardarFuncion) {
            guardarFuncion(); // Llama la función `save` específica del componente hijo.
        } else {
            console.log("No se ha definido una función de guardado específica.");
        }
    };

        // Función para manejar el botón "Guardar"
        const handleButtonClickClose = () => {
            if (cerrarFuncion) {
                cerrarFuncion(); // Llama la función `save` específica del componente hijo.
            } else {
                console.log("No se ha definido una función de cerrado específica.");
            }
        };

    const handleChangeTipoDemanda = (event, value) => {
        saveDemandaLocalStorage();
        setTipoDemandaSeleccionada(value);
    }

    // Renderizar el componente hijo según el tipo de juicio seleccionado
    const renderComponenteDemanda = () => {
        if (!tipoDemandaSeleccionada || !tipoDemandaSeleccionada.nombreMateria) {
            return null; // No renderiza nada si no hay un tipo de demanda seleccionado
        }

    
        const demandaGeneral = <FormDemanda
            onSave={setGuardarFuncion}
            juicioId={juicioId}
            nombre={nombreJuicio}
            materia={materia}
            selectCambioDemanda={renderComponentChangeDemanda}
            setSaveLocalStorage={setSaveLocalStorage}
            setCerrarFuncion={setCerrarFuncion}
            tipoDemandaSeleccionada={tipoDemandaSeleccionada}></FormDemanda>;

        const demandaFamiliar = <FormDemandaFamiliar 
            onSave={setGuardarFuncion}
            juicioId={juicioId}
            selectCambioDemanda={renderComponentChangeDemanda}
            handleEditDemanda={false}
            setSaveLocalStorage={setSaveLocalStorage}
            setCerrarFuncion={setCerrarFuncion}
            tipoDemandaSeleccionada={tipoDemandaSeleccionada}
            />
        
        switch (tipoDemandaSeleccionada.nombreMateria.toLowerCase()) {
            case "penal":
            case "justicia para adolescentes":
                let juicioPadreId = "";
                let materiaId = "";

                const juicioNombreMap = {
                    Penal: "Penal",
                    "Justicia para adolescentes": "Justicia para adolescentes (ORAL)"
                  };

                const data = datos
                    .map(element =>  element.juicios)
                    .flat()
                    .find(juicio => juicio.nombre.trim() === juicioNombreMap[materia]);

                if(data){
                    juicioPadreId = data.id;
                    materiaId = data.materiaRecord.id;
                }
                
                return <FormDemandaPenal 
                        onSave={setGuardarFuncion}
                        juicioId={juicioId}
                        nombreJuicio={nombreJuicio} 
                        materia={materia}
                        juicioPadreId={juicioPadreId}
                        setCerrarFuncion={setCerrarFuncion}
                        materiaId={materiaId}
                        />


            case "familiar":
                if (tipoDemandaSeleccionada.nombreTipoSistema === "Oral") {
                    return demandaFamiliar;
                }
                return demandaGeneral
            default:
                return demandaGeneral
        }
    };

    const renderComponentChangeDemanda = () => {
        return (<Grid container spacing={2}>
            <Grid item xs={3} sm={3} md={3}>
                <Autocomplete
                    options={tiposDemandas.sort((a, b) => a.nombreMateria.localeCompare(b.nombreMateria))}
                    getOptionLabel={(option) => option.nombreJuicio || ''}
                    groupBy={(option) => option.nombreMateria}
                    value={tipoDemandaSeleccionada || null}
                    onChange={handleChangeTipoDemanda}
                    disabled={enableChangeTipoDemanda}
                    disableClearable
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                        />
                    )}
                />

            </Grid>

            <Grid item xs={1} sm={1} md={1}>
                <Button onClick={() => setEnableChangeTipoDemanda(!enableChangeTipoDemanda)}>
                    <EditIcon />
                </Button>

            </Grid>
        </Grid>)
    }


    return (<>
        <Acciones accion1={handleButtonClick} accion2={handleButtonClickClose} />
        {renderComponenteDemanda()}
    </>)
}