import React, { useState, useRef, useEffect } from "react";
import {
  TextField,Divider,Radio,RadioGroup,
  FormHelperText,FormControl,Typography,Grid,
  Select,Box,FormControlLabel,FormLabel,
  MenuItem,InputLabel
} from "@mui/material";
import { useLocation } from "react-router-dom";

export default function FormAmparo({
  carpeta,
  formAmparoData,
  setFormAmparoData,
  tribunales,
  salas,
  sentidoAmparo,
  sentidoImpugnacion,
  handleRadioTipoAmparo,
  errorMessages,
}) {
  const location = useLocation();
  const [tipoAmparo, setTipoAmparo] = useState({tipoAmparo: ''});
  const [numeroAmparo, setNumeroAmparo] = useState([]);
  const [listTribunales, setListTribunales] = useState([]);
  const [tribunalSelect, setTribunalSelect] = useState();
  const [errorFechaTermino, setErrorFechaTermino] = useState("");
  const [errorfechaPresentacion, setErrorFechaPresentacion] = useState("");


  const { isEdit } = location.state || {};
  
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'tipoAmparo'){
      
      setTipoAmparo({ tipoAmparo: event.target.value });
      handleRadioTipoAmparo({carpetaId: carpeta.idCarpeta, clavePieza: value}, setNumeroAmparo);
      
      setListTribunales([]);
      setTribunalSelect('');
      
      if (value==='AD'){
        setListTribunales(tribunales);
      }

      if (value==='AM'){
        setListTribunales(salas);
      }
      
      
      setFormAmparoData((prevData) => ({
        ...prevData,
        tipoAmparo: value,
        impugnacion: null,
        sentidoAmparo: '',
        sentidoImpugnacion: '',
      }));
    }

    if (name === 'fechaPresentacion'){
      const fechaTermino = new Date(formAmparoData.fechaTermino);
      const fechaPresentacion = new Date(value);
      if (fechaPresentacion > fechaTermino) {
        setErrorFechaPresentacion("La fecha de presentación no puede ser posterior a la de término.");
      } else {
        setErrorFechaPresentacion(""); // Limpiar el error si es válido
        setFormAmparoData((prevData) => ({
          ...prevData,
          fechaPresentacion: value,
        }));
      }
    }

    if (name === "fechaTermino") {
      const fechaPresentacion = new Date(formAmparoData.fechaPresentacion);
      const fechaTermino = new Date(value);
  
      // Verificar que la fecha término no sea menor que la fecha presentación
      if (fechaTermino < fechaPresentacion) {
        setErrorFechaTermino("La fecha de término no puede ser anterior a la de presentación.");
      } else {
        setErrorFechaTermino(""); // Limpiar el error si es válido
        setFormAmparoData((prevData) => ({
          ...prevData,
          fechaTermino: value,
        }));
      }
    }

    if (name === 'impugnacion'){
      setFormAmparoData((prevData) => ({
        ...prevData,
       impugnacion: value
      }));

      if (value === '0'){
        setFormAmparoData((prevData) => ({
          ...prevData,
         sentidoImpugnacion: ''
        }));
      }
    }

    if (name === 'tribunal'){
      setTribunalSelect(value);

      if (tipoAmparo.tipoAmparo === 'AD'){
        setFormAmparoData((prevData) => ({
          ...prevData,
          salaId: null,
          tribunalId: value
        }));
      }

      if (tipoAmparo.tipoAmparo === 'AM'){
        setFormAmparoData((prevData) => ({
          ...prevData,
          salaId: value,
          tribunalId: null
        }));
      }
    }

    if (name === 'sentidoAmparo'){
      setFormAmparoData((prevData) => ({
        ...prevData,
        sentidoAmparo: value
      }));
    }

    if (name === 'sentidoImpugnacion'){
      setFormAmparoData((prevData) => ({
        ...prevData,
       sentidoImpugnacion: value
      }));
    }
    
  };

  const todayDefault = () => {
    const today = new Date().toISOString().split('T')[0]; 
    setFormAmparoData((prevData) => ({
      ...prevData,
     fechaPresentacion: today
    }));
  }
  useEffect(() => {  
    if (isEdit && formAmparoData.tipoAmparo) {
      handleRadioTipoAmparo(
        { carpetaId: carpeta.idCarpeta, clavePieza: formAmparoData.tipoAmparo },
        setNumeroAmparo
      );
    }
  }, [isEdit, formAmparoData.tipoAmparo]); 

  useEffect(() => {
   todayDefault();
    if (formAmparoData.tipoAmparo){
      setListTribunales(tribunales);
    }

    if (formAmparoData.tipoAmparo){
      setListTribunales(salas);
    }
    if (formAmparoData.tribunalId) {
      setTribunalSelect(formAmparoData.tribunalId);
    } else if (formAmparoData.salaId) {
      setTribunalSelect(formAmparoData.salaId);
    }
  }, [formAmparoData.tribunalId, formAmparoData.salaId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormAmparoData((prevData) => ({
      ...prevData,
      quejoso: value
    }));
  }

  return (
    <div>
      <form>
        <Grid container spacing={1} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6} md={2}>
            <Typography>Actor: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={10}>
            <Typography style={{ color: "gray" }}>
              {carpeta.actor}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Typography>Demandado: </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={10}>
            <Typography style={{ color: "gray" }}>
              {carpeta.demandado || ""}
            </Typography>
          </Grid>
        </Grid>

        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Divider />
        </Box>

        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errorMessages.tipoAmparo}
              >
                <FormLabel id="radio-tipo-amparo-label">Tipo de amparo</FormLabel>
                <RadioGroup
                  labelId="radio-tipo-amparo-label"
                  name="tipoAmparo"
                  value={formAmparoData.tipoAmparo}
                  onChange={handleChange}
                  label=""
                  error={!!errorMessages.tipoAmparo}
                >
                  <FormControlLabel value="AD" control={<Radio />} label="Directo" />
                  <FormControlLabel value="AM" control={<Radio />} label="Indirecto" />
                </RadioGroup>
                {errorMessages.tipoAmparo && (
                  <FormHelperText>{errorMessages.tipoAmparo}</FormHelperText>
                )}
              </FormControl>
            </Box>
            
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <FormControl
                fullWidth
                variant="outlined"
              >
                <TextField
                name="numero-amparo"
                label="Número"
                variant="outlined"
                fullWidth
                value={numeroAmparo}
                disabled
                />
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  label="Fecha de presentación: "
                  name="fechaPresentacion"
                  fullWidth
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  error={errorMessages.fechaPresentacion}
                  value={formAmparoData.fechaPresentacion}
                  onChange={handleChange}
                />
                {errorMessages.fechaPresentacion && (
                  <FormHelperText>{errorMessages.fechaPresentacion}</FormHelperText>
                )}
                {errorfechaPresentacion && (
  <FormHelperText error>{errorfechaPresentacion}</FormHelperText>
)}
              </Grid>
              <Grid item>
              <TextField
  label="Fecha término"
  name="fechaTermino"
  fullWidth
  type="date"
  InputLabelProps={{ shrink: true }}
  error={!!errorFechaTermino} // Si hay un error, el campo se marca como error
  value={formAmparoData.fechaTermino}
  onChange={handleChange}
/>
{errorFechaTermino && (
  <FormHelperText error>{errorFechaTermino}</FormHelperText>
)}


              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <FormControl
                fullWidth
                variant="outlined"
                error={!!errorMessages.impugnacion}
              >
                <FormLabel id="radio-impugnacion-label">Impugnación</FormLabel>
                <RadioGroup
                  labelId="radio-impugnacion-label"
                  name="impugnacion"
                  value={formAmparoData.impugnacion}
                  label=""
                  error={!!errorMessages.impugnacion}
                  onChange={handleChange}
                >
                  <FormControlLabel value="1" control={<Radio />} label="Sí" />
                  <FormControlLabel value="0" control={<Radio />} label="No" />
                </RadioGroup>
                {errorMessages.impugnacion && (
                  <FormHelperText>{errorMessages.impugnacion}</FormHelperText>
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
                label="Quejoso: "
                name="quejoso"
                fullWidth
                InputLabelProps={{ shrink: true }}
                error={errorMessages.quejoso}
                value={formAmparoData.quejoso}
                onChange={(e) => handleInputChange(e)}
            />
            {errorMessages.quejoso && (
              <FormHelperText>{errorMessages.quejoso}</FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-tribunal-label">Tribunal</InputLabel>
              <Select
                  labelId="select-tribunal-label"
                  name="tribunal"
                  value={tribunalSelect || ''}
                  onChange={handleChange}
                  label="Tribunal"
                  error={!!errorMessages.tribunal}
                  required
              >
                  {listTribunales.map((tribunal) => (
                      <MenuItem key={tribunal.id} value={tribunal.id}>
                          {tribunal.nombre}
                      </MenuItem>
                  ))}
              </Select>
              <FormHelperText >{errorMessages.tribunal || ''}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-sentido-amapro-label">Sentido de amparo</InputLabel>
              <Select
                    labelId="select-sentido-amparo-label"
                    name="sentidoAmparo"
                    value={formAmparoData?.sentidoAmparo|| ''}
                    onChange={handleChange}
                    label="Sentido de amparo"
                    error={!!errorMessages.sentidoAmparo}
                    required
                >
                    {sentidoAmparo.map((e) => (
                        <MenuItem key={e.clave} value={e.clave}>
                            {e.etiqueta}
                        </MenuItem>
                    ))}
              </Select>
              <FormHelperText >{errorMessages.sentidoAmparo || ''}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-sentido-impugnacion-label">Sentido de la impugnación</InputLabel>
              <Select
                    labelId="select-sentido-impugnacion-label"
                    name="sentidoImpugnacion"
                    value={formAmparoData?.sentidoImpugnacion|| ''}
                    onChange={handleChange}
                    label="Sentido de la impugnación"
                    error={!!errorMessages.sentidoImpugnacion}
                    disabled={formAmparoData.impugnacion==='0'}
                    required={formAmparoData.impugnacion==='1'}
                >
                    {sentidoImpugnacion.map((e) => (
                        <MenuItem key={e.clave} value={e.clave}>
                            {e.etiqueta}
                        </MenuItem>
                    ))}
                </Select>
              <FormHelperText>{errorMessages.sentidoImpugnacion || ''}</FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
