import React from 'react';
import { Grid, TextField, IconButton, Box, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Anexo = ({
  anexos,
  handleAnexoChange,
  handleRemoveAnexo,
  handleAddAnexo,
  anexosErrors,
  anexoRefs,
  isPreviewDemanda
}) => {
  return (
    <Grid item xs={10} mb={2} ml={7}>
      {anexos.map((anexo, index) => (
        <Grid container alignItems="center" key={index} sx={{ mt: 2 }}>
          <Grid item xs>
            <TextField
              label={`Anexo ${index + 1}`}
              fullWidth
              disabled={isPreviewDemanda}
              value={anexo}
              onChange={(e) => handleAnexoChange(index, e.target.value)}
              inputRef={(el) => (anexoRefs.current[index] = el)}
              helperText={
                anexosErrors[index] &&
                "El anexo debe tener al menos 3 caracteres"
              }
              FormHelperTextProps={{
                style: {
                  color: anexosErrors[index] !== "" ? "#d32f2f" : "inherit",
                },
              }}
            />
          </Grid>

          {!isPreviewDemanda && (
            <Grid item>
              <IconButton onClick={() => handleRemoveAnexo(index)}>
                <DeleteIcon className='icon-color' />
              </IconButton>
            </Grid>
          )}


        </Grid>
      ))}
      {!isPreviewDemanda && (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="flex-start" mt={2}>
            <Button
              className='button-validation'
              startIcon={<AddCircleOutlineIcon />}
              variant="contained"
              onClick={handleAddAnexo}
              disabled={isPreviewDemanda}
            >
              Agregar Anexo
            </Button>
          </Box>
        </Grid>
      )}

    </Grid>
  );
};

export default Anexo;
