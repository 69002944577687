import { Alert, Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../components/Breadcrumb/breadcrumbs";
import CanvasGeneradorEtiquetas from "./CanvasGeneradorEtiquetas";
import FormGeneradorEtiquetas from "./FormGeneradorEtiquetas";



const GeneradorEtiquetas = () => {
    const [tipoGeneracion, setTipoGeneracion] = useState(1);


    return (
        <>
            <Grid container spacing={2} sx={{ mt: 2 }}>
                <Breadcrumbs istitulo="true" className="title" />

                <Grid item sm={12}>
                    <Alert severity="warning">
                        SI REQUIERE ETIQUETAS AUTOADHERIBLES FAVOR DE SOLICITAR EN EL ALMACEN PARA IMPRESORA LASER MODELO DE JOJAS J-5260
                    </Alert>
                </Grid>

                <Grid item lg={6} md={6} sm={12}>
                    <FormControl fullWidth variant="outlined">
                        <InputLabel id="select-type-label">Tipo de generación:</InputLabel>
                        <Select
                            labelId="select-type-label"
                            label="Tipo de generación"
                            name="tipoGeneracionName"
                            value={tipoGeneracion}
                            onChange={(e) => setTipoGeneracion(e.target.value)}
                        >
                            <MenuItem key={1} value={1}>
                                Generación etiquetas números continuos (Ejemplo 000001 - 000030)
                            </MenuItem>
                            <MenuItem key={2} value={2}>Generar etiquetas individuales</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>


            </Grid>

            {tipoGeneracion === 1 ? (
                    <FormGeneradorEtiquetas />
                ) : <CanvasGeneradorEtiquetas />}
        </>
    );
};

export default GeneradorEtiquetas;
