import { Box, Button, FormControl, Grid, Paper, TextField } from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import { getQrPorCasilla } from "./GeneradorEtiquetasService";
import { useToastAlert } from "../../components/Alert/ToastAlertSave/ToastAlertSave";
import keycloakConf from "../../api/keycloakConf";
import { onlyNumbers } from "../../components/Caracteres/caracteresEspeciales";
import { validateFields } from "../../components/Alert/ValidationRequired/validationRequired";
import { useToastAlertValidation } from "../../components/Alert/ToastAlertValidation/ToastAlertValidation";

const CanvasGeneradorEtiquetas = () => {
    const ROWS = 10;
    const COLS = 3;
    const CELL_WIDTH = 90;
    const CELL_HEIGHT = 45;
    const canvasRef = useRef(null);
    const [selectedCell, setSelectedCell] = useState(null);
    const { showSnackbar } = useToastAlert();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [formData, setFormData] = useState({
        expediente: "",
        year: ""
    });
    const [errors, setErrors] = useState([]);

    const { getToken } = useContext(keycloakConf);

    useEffect(() => {
        drawGrid();

    }, [selectedCell]);


    const handleChangeField = (e) => {
        const { name, value } = e.target;

        setFormData(prevState => ({
            ...prevState,
            [name]: onlyNumbers(value)
        }))

    }

    const drawGrid = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        for (let row = 0; row < ROWS; row++) {
            for (let col = 0; col < COLS; col++) {
                let x = col * CELL_WIDTH;
                let y = row * CELL_HEIGHT;

                ctx.fillStyle =
                    selectedCell?.row === row && selectedCell?.col === col ? "#f39c12" : "#ecf0f1";
                ctx.fillRect(x, y, CELL_WIDTH, CELL_HEIGHT);
                ctx.strokeStyle = "#000";
                ctx.strokeRect(x, y, CELL_WIDTH, CELL_HEIGHT);

                if (selectedCell?.row === row && selectedCell?.col === col) {
                    ctx.fillStyle = "#fff";
                    ctx.font = "bold 15px Arial";
                    ctx.textAlign = "center";
                    ctx.fillText("✔", x + 45, y + 26);
                }
            }
        }
    };

    const handleCanvasClick = (event) => {
        const rect = canvasRef.current.getBoundingClientRect();


        const x = event.clientX - rect.left;
        const y = event.clientY - rect.top;

        const col = Math.floor(x / CELL_WIDTH);
        const row = Math.floor(y / CELL_HEIGHT);
        setSelectedCell({ row, col });
    };

    const handleGenerarEtiqueta = async () => {
        const casilla = selectedCell.row * COLS + selectedCell.col + 1;
        const year = formData.year;
        const expediente = formData.expediente;
        try {
            const token = getToken();
            const documento = await getQrPorCasilla(token, expediente, year, casilla);
            var blob = new Blob([documento], { type: "application/pdf" });
            let link = document.createElement("a");

            link.href = window.URL.createObjectURL(blob);
            link.target = "etiquetas.pdf";
            link.click();
            showSnackbar("Documento generado con éxito", "success");
        } catch (error) {
            console.log("error al generar documento", error);
            const razonError = error.response.status = 404 ? "No existen expedientes con los criterios de búsqueda." : "Ha ocurrido un error";
            showSnackbar(razonError, "error");
        }
    }

    const handleValidarCampos = () => {
        const rules = {
            'expediente': { required: true },
            'year': { required: true }
        }

        const errores = validateFields(formData, rules, showAlertValidate);
        setErrors(errores);

       

        if (Object.keys(errores).length === 0) {
            if(!selectedCell){
                showSnackbar("Es necesario seleccionar una casilla.", "error");
                return;
            }
            handleGenerarEtiqueta();
        }
    }


    return <>
        <Grid container spacing={2} sx={{ mt: 2 }}>

            <Grid item sm={4}>
                <FormControl fullWidth variant="outlined">
                    <TextField
                        label="Expediente"
                        name="expediente"
                        value={formData.expediente}
                        onChange={(e) => handleChangeField(e)}
                        placeholder="Ingrese el número de expediente"
                        margin="normal"
                        inputProps={{ maxLength: 6 }}
                        error={!!errors["expediente"]}
                        helperText={errors["expediente"] || ''}
                    />
                </FormControl>

                <FormControl fullWidth variant="outlined">
                    <TextField
                        label="Año"
                        name="year"
                        value={formData.year}
                        onChange={(e) => handleChangeField(e)}
                        placeholder="Ingrese el año"
                        margin="normal"
                        inputProps={{ maxLength: 4 }}
                        error={!!errors["year"]}
                        helperText={errors["year"] || ''}
                    />
                </FormControl>

                <Box mt={2}>
                        <Button variant="contained"
                            className="button-save" onClick={handleValidarCampos}>
                            Generar etiqueta
                        </Button>
                    </Box>
            </Grid>

            <Grid item sm={1}></Grid>

            {/*  Canvas de selección */}
            <Grid item lg={6} md={6} sm={12} display="flex" flexDirection="column">
                <Paper elevation={3} sx={{ p: 2, textAlign: "center" }}>
                    <canvas
                        ref={canvasRef}
                        width={COLS * CELL_WIDTH}
                        height={ROWS * CELL_HEIGHT}
                        style={{ border: "3px solid #ddd", cursor: "pointer" }}
                        onClick={handleCanvasClick}
                    />
                </Paper>
            </Grid>

        </Grid>




    </>

}

export default CanvasGeneradorEtiquetas;