import axios from "../../api/axiosConfig";

export const fetchGetMensajeros = async (token) => {
    try {
        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/personas/mensajeros`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        return response.data;
    } catch (error) {
        console.error('Error al listar mensajeros:', error);
        throw error;
    }
}

export const fetchGetBandejaSalida = async (token, page = 0, size = 10, sort= '', key='') => {
    
    try {

        const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + '/api/workflow/bandejaEnvios/', {
            headers: {
                Authorization: `Bearer ${token}`
            },
            params: {
                page,
                size,
                sort,
                key
            },
        });

        return response.data;
    } catch (error) {
        console.error("Error al obtener la bandeja de envios");
        
    }
}

export const cambioEstatusBandejaEnviados = async (token, body) => {
    try {
      const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandejaEnvios/`, body, { 
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      return response.data;
    } catch (error) {
      console.error('Error al cambiar estatus de items del carrito de envios:', error);
      throw error;
    }
  };

  export const digitalizarAcuseOficioOCP = async (token, data) => {

    try {
        const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandejaEnvios/digitalizacion`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error al digitalizar el documento:', error);
        throw error;
    }
};

export const obtenerAcuseDocumentoOCP = async(token, documentoId) =>{
 
    try {
      const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/bandejaEnvios/digitalizacion/${documentoId}`,{
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      
      return response.data;
  
    } catch (error) {
      console.log('Error al descargar el documento: ', error);
      
      
    }
  
  }