import { Box, Card, Checkbox, FormControlLabel, Grid, Typography, TextField, Button, Modal, IconButton, Divider } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../../api/keycloakConf";
import { useToastAlertValidation } from "../../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { UpdateEstatusAnexos, getDocumento } from "../BandejaService";
import { useNavigate, Link } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';

const Recepcion = ({ item, onOpen, onClose, data, onSave }) => {
    const { getToken } = useContext(keycloakConf);
    const { showSnackbar, showConfirmDialog } = useToastAlert();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const navigate = useNavigate();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "80%",
        bgcolor: 'white',
        borderRadius: '8px',
        boxShadow: 20,
        p: 4,
    };

    useEffect(() => {
        setFormData({
            id: data?.id || "",
            folio: data?.folio || "",
            expediente: data?.expediente || "",
            tipoEntrada: data?.tipoEntrada || "",
            origen: data?.origen || "",
            digitalizacion: data?.digitalizacion || "",
            anexos: data?.anexos || [],
            observaciones: data?.observaciones || "",
            recomendaciones: data?.recomendaciones || "",
        })
    }, [data]);

    const [formData, setFormData] = useState({});


    const handleSubmit = async () => {

        const token = await getToken();
        const bodyData = {
            id: formData.id,
            anexos: formData.anexos,
            observaciones: formData.observaciones,
            recomendaciones: formData.recomendaciones,
        };

        onSave(bodyData);

        //ignora esta parte ya que la reutilkizare despues
        return;
        try {
            await UpdateEstatusAnexos(token, item.id, bodyData);
            showSnackbar("Registro recibido. Revise su bandeja de asignados.", "success");
            navigate("/api/bandeja/recepcion");
        } catch (error) {
            console.error("Error al actualizar el estatus de los anexos:", error);
            showSnackbar("¡Algo salió mal!", "error");
        }
    };


    const setAnexoEstado = (e, anexoId) => {
        const estado = e.target.checked ? 1 : 0;

        setFormData((prevState) => ({
            ...prevState,
            anexos: prevState.anexos.map((anexo) =>
                anexo.id === anexoId ? { ...anexo, estado } : anexo
            ),
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleDownloadDocumento = async () => {
        try {
            const documento = await getDocumento(getToken(), item.id);
            let blob = new Blob([documento], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.target = "_blank";
            link.click();
        } catch (error) {
            console.error("Error al generar el documento:", error);
            showSnackbar("¡Algo salió mal!", "error");
        }
    };

    return (
        <>
            {/* Modal que contiene el contenido principal */}
            <Modal open={onOpen} onClose={onClose} >
                <Box sx={style}>
                    <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', color: '#333' }}>
                            Anotaciones
                        </Typography>
                        <IconButton onClick={() => {
                            onClose();
                        }}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Divider sx={{ my: 1 }} />

                    <Box>
                        {/* Información del formulario */}
                        <Grid container spacing={2} sx={{ marginTop: 1 }}>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="body1">{`Folio: ${formData.folio}`}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="body1">{`Expediente: ${formData.expediente}`}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="body1">{`Tipo: ${formData.tipoEntrada === "Promocion" ? "Promoción" : formData.tipoEntrada}`}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="body1">{`Origen: ${formData.origen}`}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Typography variant="body1" component="span">{`Digitalización: `}</Typography>
                                {item?.tipoEntrada !== "APELACION" && (
                                    <Link
                                        onClick={(e) => {
                                            e.preventDefault();
                                            handleDownloadDocumento();
                                        }}
                                        sx={{ textDecoration: "none", color: "blue", cursor: "pointer" }}
                                    >
                                        {formData.digitalizacion}
                                    </Link>
                                )}
                            </Grid>
                        </Grid>

                        <Divider sx={{ my: 1 }} />

                        <Grid container spacing={2} sx={{ marginTop: 4 }}>
                            <Grid item sm={6}>
                                {formData?.anexos?.length > 0 && (<>
                                    <Typography variant="body1">Recepción de anexos</Typography>
                                    <Card variant="outlined">
                                        {formData.anexos.map((anexo) => (
                                            <Grid item xs={12} key={anexo.id} sx={{ margin: "10px" }}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={anexo.estado === 1}
                                                            onChange={(e) => setAnexoEstado(e, anexo.id)}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={anexo.nombre}
                                                />
                                            </Grid>
                                        ))}

                                    </Card>
                                </>)}

                                {formData?.anexos?.length === 0 && (
                                    <Typography sx={{ textAlign: 'center', my: 2 }} variant="body1">Sin anexos</Typography>
                                )}

                            </Grid>

                            <Grid item sm={6}>
                                <TextField
                                    label="Observaciones"
                                    name="observaciones"
                                    fullWidth
                                    variant="outlined"
                                    value={formData.observaciones}
                                    onChange={handleInputChange}
                                    helperText={`${formData?.observaciones?.length}/300 caracteres`}
                                    inputProps={{ maxLength: 300 }}
                                    multiline
                                    rows={3}
                                    maxRows={3}
                                    FormHelperTextProps={{
                                        style: { textAlign: "right" },
                                    }}
                                />
                                <TextField
                                    label="Recomendaciones"
                                    name="recomendaciones"
                                    fullWidth
                                    variant="outlined"
                                    value={formData.recomendaciones}
                                    onChange={handleInputChange}
                                    helperText={`${formData?.recomendaciones?.length}/60 caracteres`}
                                    inputProps={{ maxLength: 60 }}
                                    multiline
                                    rows={3}
                                    maxRows={3}
                                    FormHelperTextProps={{
                                        style: { textAlign: "right" },
                                    }}
                                />
                            </Grid>

                        </Grid>

                    </Box>


                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 5 }}>
                        <Button variant="outlined" className="button-close" onClick={() => { onClose() }} >
                            Cancelar
                        </Button>
                        <Button variant="contained" className="button-save" style={{ marginLeft: '10px' }} onClick={() => {
                            handleSubmit();
                        }}>
                            Guardar
                        </Button>
                    </Box>
                </Box>


            </Modal>
        </>
    );
};

export default Recepcion;
