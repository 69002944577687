import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import KeycloakConf from '../../api/keycloakConf';

export default function useMenuItems() {
    const [items, setItems] = useState([]);
    const [cargando, setCargando] = useState(false);
    const { getToken } = useContext(KeycloakConf);

    useEffect(() => {
        const fetchMenuItems = async () => {
            setCargando(true);
            try {
                const token = getToken();
                const response = await axios.get(`${process.env.REACT_APP_TRIALS_API_URL}/api/core/menu`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const menuItems = response.data.map(section => ({
                    ...section,
                    displayName: section.name,
                    icon: "none"
                }));
                sessionStorage.setItem("menu", JSON.stringify(menuItems));

                setItems(menuItems);
            } catch (error) {
                console.error('Error al obtener datos de ítems:', error);
            } finally {
                setCargando(false);
            }
        };

        if (sessionStorage.getItem("menu") == null) {
            fetchMenuItems();
        } else {
            const menuItems = JSON.parse(sessionStorage.getItem("menu"));
            setItems(menuItems);
        }
        if (sessionStorage.getItem("policies") == null) {
            getPolicies();
        }

        
    }, [getToken]);

    const getPolicies = async () => {
        try {
            const token = getToken();
            const response = await axios.get(process.env.REACT_APP_TRIALS_API_URL + `/api/core/recursos`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            sessionStorage.setItem("policies", JSON.stringify(response.data));
        } catch (error) {
            console.error('Error al obtener permisos:', error);
        }
    };



    return { items, cargando };
}
