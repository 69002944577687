import { Box, Grid, Paper, styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "../../components/Breadcrumb/breadcrumbs";
import PaginationGeneric from "../../components/Pagination/PaginationGeneric";
import Glosario from "../../assets/Glosario";
import SendIcon from '@mui/icons-material/Send';
import ReturnBandejasModal from "../../components/Modal/ReturnBandejasModal";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const areasTurnado = [
    { value: 'digitalizacion', label: "Digitalización" },
    { value: 'salida', label: "Bandeja de salida" }]

const BandejaDevueltosTable = ({
    bandejaDevueltos,
    pageable,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    handleRefresh
}) => {

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedExpediente, setSelectedExpediente] = useState(null);
    const [isRecepcionModalOpen, setIsRecepcionModalOpen] = useState(false);
    const [recepcionItem, setRecepcionItem] = useState(null);

    const handleOpenModal = (expediente, documentoId, carpetaId) => {
        setIsModalOpen(true);
        setSelectedExpediente({ expediente: expediente, documentoId:documentoId, carpetaId:carpetaId });
    }

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedExpediente(null);
    };

    const handleSave = (data) => {
        handleCloseModal();
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Grid className="box-sombra">
                <Breadcrumbs istitulo="true" className="title2" />
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <PaginationGeneric
                        pageable={pageable}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />

                    <TableContainer sx={{ maxHeight: 600, overflowY: 'auto' }}>
                        <Table stickyHeader sx={{ minWidth: 750 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='table-header'>{Glosario.Folio} </TableCell>
                                    <TableCell className='table-header'>{Glosario.NoExpediente} </TableCell>
                                    <TableCell className='table-header'>{Glosario.TipoEntrada} </TableCell>
                                    <TableCell className='table-header'>{Glosario.Origen} </TableCell>
                                    <TableCell className='table-header'>{Glosario.Devolucion} </TableCell>
                                    <TableCell className='table-header'>{Glosario.FechaHoraEnvio} </TableCell>
                                    <TableCell className='table-header'>{Glosario.Acciones} </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {bandejaDevueltos.map((item) => (
                                    <StyledTableRow
                                        key={item.carpetaId}
                                        hover>
                                        <TableCell>{item.folio}</TableCell>
                                        <TableCell>{item.expediente}</TableCell>
                                        <TableCell>{item.tipoEntrada === "Promocion" ? 'Promoción' : item.tipoEntrada}</TableCell>
                                        <TableCell>{item.origen}</TableCell>
                                        <TableCell>{item.motivoDevolucion}</TableCell>
                                        <TableCell>{new Date(item.fechaHoraEnvio).toLocaleString()}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Asignar">
                                                <SendIcon
                                                    style={{ marginRight: '10px' }}
                                                    className="icon-color"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleOpenModal(item.expediente, item.documentoId, item.carpetaId)
                                                    }}
                                                />
                                            </Tooltip>
                                        </TableCell>
                                    </StyledTableRow>
                                ))}
                                {bandejaDevueltos.length === 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            No hay registros disponibles.
                                        </TableCell>
                                    </TableRow>
                                )}

                            </TableBody>

                        </Table>
                    </TableContainer>

                    <PaginationGeneric
                        pageable={pageable}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </Paper>
            </Grid>


            <ReturnBandejasModal
                isOpen={isModalOpen}
                handleClose={handleCloseModal}
                expediente={selectedExpediente}
                handleSave={handleSave}
                handleRefresh={handleRefresh}
            />
        </Box>

    )
}

export default BandejaDevueltosTable;