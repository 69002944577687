import axios from "../../../api/axiosConfig";

export const fetchReasignarExpediente = async (token, data) => {
    try {

        const response = await axios.post(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/reasignacionExpediente`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        console.log(response);
        
        return response.data; 
    } catch (error) {   
        console.error('Error al reasignar el expediente:', error);
        throw error;
    }
}

export const fetchReactivacionExpediente = async (token, carpetaId) => {
    try {
      const response = await axios.patch(process.env.REACT_APP_TRIALS_API_URL + `/api/workflow/reactivacionExpediente/${carpetaId}`, null, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      return response;
    } catch (error) {
      console.error('Error al cambiar reactivar el expediente:', error);
      throw error;
    }
  };