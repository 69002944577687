import React, { createContext, useState, useEffect, useCallback } from 'react';
import Keycloak from 'keycloak-js';

const keycloakConf = createContext();

const keycloakConfig = {
  url: process.env.REACT_APP_KEYCLOAK_API_URL,
  realm: 'trials-realm',
  clientId: 'trials-ui',
};

export const KeycloakProvider = ({ children }) => {
  const [keycloak, setKeycloak] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const initKeycloak = async () => {
      const keycloakInstance = new Keycloak(keycloakConfig);
      try {
        const auth = await keycloakInstance.init({ onLoad: 'login-required' });
        setKeycloak(keycloakInstance);
        setAuthenticated(auth);
        if (auth) {
          console.log('Autenticado con éxito');
        } else {
          console.log('No autenticado');
        }
      } catch (error) {
        console.error(`Error de Keycloak: ${error}`);
      } finally {
        setLoading(false);
      }
    };

    initKeycloak();
  }, []);

  const login = () => {
    keycloak.login();
  };


  const logout = useCallback(() => {
    if (keycloak) {
      sessionStorage.removeItem("menu");
      sessionStorage.removeItem("policies");
      sessionStorage.removeItem("centroTrabajo");
      keycloak.logout({ redirectUri: window.location.origin });
    }
  }, [keycloak]);

  const getToken = () => {
    return keycloak ? keycloak.token : null;
  };

  useEffect(() => {
    const refreshToken = async () => {
      if (keycloak) {
        try {
          await keycloak.updateToken(30);
        } catch (error) {
          console.error('Error actualizando token:', error);
          logout(); 
        }
      }
    };
  
    const intervalId = setInterval(refreshToken, 60000);
    return () => clearInterval(intervalId);
  }, [keycloak, logout]); 

  return (
    <keycloakConf.Provider value={{ authenticated, loading, login, logout, getToken }}>
      {!loading && children}
    </keycloakConf.Provider>
  );
};

export default keycloakConf;
