import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import keycloakConf from "../../../../api/keycloakConf";
import { fetchAudienciasGenerales } from "../../../../shared/AudienciasService";
import BandejaAudienciaTable from '../../../BandejaAudiencias/BandejaAudienciaTable'
import RefreshButton from "../../../../components/Button/RefreshButton";
import ButtonAdd from '../../../../components/Button/ButtonAdd';
import ConfirmationDialog from "../../../../components/Alert/ConfirmationDialog";
import { removeAudiencia } from "../../../../shared/AudienciasService";
import { useItem } from "../../../../components/ItemProvider/ItemContext";

const Audiencias = ({  isConsulta }) => {
    const navigate = useNavigate();
    const [bandejas, setData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { getToken } = useContext(keycloakConf);
    const [loading, setLoading] = useState(true);
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const { item } = useItem();

    const [itemToDelete, setItemToDelete] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [toastType, setToastType] = useState('success');
    const [toastOpen, setToastOpen] = useState(false);
    const [isTab] = useState(false);

    useEffect(() => {
        loadBandejaAudiencia();
    }, [getToken, page, rowsPerPage]);

    const loadBandejaAudiencia = async () => {
        setLoading(true);
        const token = getToken();
        setSearchQuery(item.expediente);
        try {
            const bandejaAudienciaData = await fetchAudienciasGenerales(token, page, rowsPerPage, '', item.expediente);
            setData(bandejaAudienciaData.content);
            setPageable(bandejaAudienciaData.page);
        } catch (error) {
            console.error('Error cargando registros:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = () => {
        setSearchQuery('');
        setPage(0);
        loadBandejaAudiencia();
    };

    const handleDelete = (id) => {
        setDialogMessage(`La audiencia se eliminará de forma permanente`);
        setItemToDelete(id);
        setDialogOpen(true);
    };

    const handleConfirmDelete = async () => {
        const token = getToken();
        try {
            await removeAudiencia(token, itemToDelete);
            setToastMessage('Registro eliminado con éxito');
            setToastType('success');
            await loadBandejaAudiencia();
        } catch (error) {
            console.error('Error eliminando registro:', error);
            setToastMessage('El registro no fue eliminado');
            setToastType('error');
        }
        setToastOpen(true);
        setDialogOpen(false);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleCrearAudiencia = () => {
        navigate("/api/bandeja/audiencias/crearAudiencias", {
            state: {
                item: item,
                tabnav: 3
            }
        });
    }

    return (
        <>
            <Box mb={2} display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                        
                    {!(item.tipoEntrada === "Pieza" || !item.etapaProcesal) && (
                        <ButtonAdd onClick={handleCrearAudiencia} isDisabled={isConsulta} />
                    )}
                    &nbsp;
                    <RefreshButton onClick={handleRefresh} />
                </Box>
            </Box>

            <Box>
                {loading ? <p>Cargando ... </p> : (
                    <BandejaAudienciaTable
                        bandejas={bandejas}
                        token={getToken()}
                        onDelete={handleDelete}
                        loadBandejaAudiencia={loadBandejaAudiencia}
                        pageable={pageable}
                        page={page}
                        setPage={setPage}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        handleRefresh={handleRefresh}
                        isTab={true}
                        isPieza={item.tipoEntrada === "Pieza"}
                        isConsulta={isConsulta}
                        hasNotEtapaProcesal={!item.etapaProcesal}
                        itemAsignado={item}
                    />
                )}
            </Box>

            <ConfirmationDialog
                open={dialogOpen}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmDelete}
                message={dialogMessage}
            />
        </>
    );
};

export default Audiencias;