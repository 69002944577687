import { Box, Button, Typography, Grid } from "@mui/material";
import { useEffect, useState } from "react";

/**
 * Componente `Acciones` que muestra dos botones (con funcionalidad opcional) y la fecha y hora actual.
 * También incluye soporte para un tooltip personalizado.
 *
 * Props:
 * - `accion1`: Función que se ejecuta al hacer clic en el primer botón.
 * - `textoBoton1`: Texto que se muestra en el primer botón (por defecto, "Guardar").
 * - `isVisibleBtn1`: Booleano que determina si el primer botón es visible.
 * - `accion2`: Función que se ejecuta al hacer clic en el segundo botón.
 * - `textoAccion2`: Texto que se muestra en el segundo botón (por defecto, "Cerrar").
 * - `isVisibleBtn2`: Booleano que determina si el segundo botón es visible.
 * - `toolTip`: Función que devuelve un componente de tooltip adicional (opcional).
 * -  hasDateAndTime: Booleano que determina si se muestra la fecha y hora.
 */
const Acciones = ({
    accion1,
    textoBoton1 = "Guardar",
    isVisibleBtn1 = true,
    accion2,
    textoAccion2 = "Cerrar",
    isVisibleBtn2 = true,
    toolTip = false,
    isDisabled = false,
    hasDateAndTime = true
}) => {
    // Estado para almacenar la fecha y hora actual
    const [fechaHoraActual, setFechaHoraActual] = useState({ fecha: '', hora: '' });

    // useEffect para actualizar la fecha y hora actual cada segundo
    useEffect(() => {
        if (hasDateAndTime) {
            const interval = setInterval(() => {
                const now = new Date(); // Obtiene la fecha y hora actual
                setFechaHoraActual({
                    fecha: now.toLocaleDateString(), // Formato de fecha local
                    hora: now.toLocaleTimeString(), // Formato de hora local
                });
            }, 1000); // Actualiza cada 1000 ms (1 segundo)

            // Limpia el intervalo al desmontar el componente
            return () => clearInterval(interval);
        }

    }, []);

    return (
        <Grid item xs={12} className="static-buttons">
            {/* Contenedor principal de la sección */}
            <Box
                sx={{
                    display: "flex", // Usa un layout flexible
                    justifyContent: "space-between", // Distribuye elementos uniformemente
                    alignItems: "center", // Alinea elementos verticalmente
                    mb: 1, // Margen inferior
                    pb: 1.5, // Padding inferior
                    borderBottom: "1px solid #e0e0e0", // Línea separadora
                }}
            >
                {/* Sección de botones */}
                <Box>
                    {isVisibleBtn1 && (
                        <Button
                            type="button"
                            variant="contained"
                            className="button-save"
                            onClick={accion1} // Llama a la función asociada al botón 1
                            disabled={isDisabled}
                        >
                            {textoBoton1}
                        </Button>
                    )}

                    {isVisibleBtn2 && (
                        <Button
                            type="button"
                            variant="contained"
                            className="button-close"
                            onClick={accion2} // Llama a la función asociada al botón 2
                            style={{ marginLeft: "10px" }} // Margen izquierdo entre botones
                        >
                            {textoAccion2}
                        </Button>
                    )}
                </Box>

                {/* Sección de fecha y hora */}
                {hasDateAndTime && (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        container
                        justifyContent="flex-end" // Alinea a la derecha
                        alignItems="center" // Centra verticalmente
                    >
                        {/* Muestra la fecha y hora actual */}
                        <Typography variant="body1" sx={{ mr: 2 }} style={{ border: '2px solid #636569', padding: '10px', borderRadius: '4px' }} >
                            Fecha: {fechaHoraActual.fecha} | Hora: {fechaHoraActual.hora}
                        </Typography>

                        {/* Renderiza el tooltip si está definido */}
                        {toolTip && toolTip()}
                    </Grid>
                )}

            </Box>
        </Grid>
    );
};

export default Acciones;
