import { Alert, AlertTitle, Autocomplete,  Button,  FormControl, Grid, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { fetchJuzgadoAllByEstatusInactiveOrActive } from "../../../shared/services/JuzgadosService";
import keycloakConf from "../../../api/keycloakConf";
import Breadcrumbs from "../../../components/Breadcrumb/breadcrumbs";
import { fetchCarpetaActorDemandado } from "../../../shared/services/CarpetaService";
import { useToastAlertValidation } from "../../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import { useToastAlert } from "../../../components/Alert/ToastAlertSave/ToastAlertSave";
import ConfirmationModal from "../../../components/Modal/ConfirmationModal";
import { fetchReactivacionExpediente, fetchReasignarExpediente } from "./ReasignacionExpedienteService";

const ReasignacionExpediente = () => {
    const [errorMessages, setErrorMessages] = useState([]);
    const { showSnackbar } = useToastAlert();
    const { showSnackbar: showAlertValidate } = useToastAlertValidation();
    const [modalOpen, setModalOpen] = useState(false);
    const [juzgados, setJuzgados] = useState([]);
    const { getToken } = useContext(keycloakConf);
    const [accion, setAccion] = useState();
    const [datosValidacion, setDatosValidacion] = useState({
        expediente: "",
        year: "",
        juzgado: ""
    });
    const [carpeta, setCarpeta] = useState({});

    useEffect(() => {
        loadJuzgados();
    }, [])

    const handleChangeFieldsValidation = (e) => {

        const { name, value } = e.target;

        setDatosValidacion(prevData => ({
            ...prevData,
            [name]: value
        }));

    }

    const handleValidate = async () => {
        try {
            const token = getToken();
            const data = {
                "numExpediente": datosValidacion.expediente,
                "year": datosValidacion.year,
                "idJuzgado": datosValidacion.juzgado.id
            }

            const carpeta = await fetchCarpetaActorDemandado(token, data);
            setCarpeta(carpeta);
            showSnackbar("Carpeta encontrada con éxito", "success");

        } catch (error) {
            console.log("Error al obtener la carpeta");
            showSnackbar("Error al obtener la carpeta", "error");

        }
    }

    const handleConfirmReasignarExpediente =  (e) => {
        setAccion(e.target.name)
        setModalOpen(true);
    }

    const handleReasignarExpediente = async () => {
        const token = getToken();
        const data = { carpetaParentId: carpeta.idCarpeta };
        try{
            const response = await fetchReasignarExpediente(token, data);
            if(response.carpetaId !== null){
                showSnackbar("Se ha reasignado el expediente exitosamente", "success");
            }

        }catch(e){
            let textError = e.response.data.detail ? e.response.data.detail : "Ha ocurrido un error";
            showSnackbar(textError, "error");
        }
       
    }

    const handleReactivacionExpediente = async () => {
        const token = getToken();
        try {
            const response = await fetchReactivacionExpediente(token, carpeta.idCarpeta);
            if(response.carpetaId !== null){
                setCarpeta((prevState) => ({
                    ...prevState,
                    estadoCarpeta: "Recepción"
                  }));
                showSnackbar("Se ha reasignado el expediente exitosamente", "success");
            }
        } catch (e) {
            let textError = e.response.data.detail ? e.response.data.detail : "Ha ocurrido un error";
            showSnackbar(textError, "error");
        }
    }

    const loadJuzgados = async () => {
        try {
            const token = getToken();
            const juzgados = await fetchJuzgadoAllByEstatusInactiveOrActive(token);
            setJuzgados(juzgados)

        } catch (error) {
            console.log("Error al obtener los Juzgados");
        }
    };

    return <>
        <Grid className="box-sombra">
            <Breadcrumbs istitulo="true" className="title2"></Breadcrumbs>
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            name="expediente"
                            label="Expediente"
                            variant="outlined"
                            fullWidth
                            value={datosValidacion?.expediente || ''}
                            onChange={handleChangeFieldsValidation}
                            error={!!errorMessages?.expediente}
                            helperText={errorMessages?.expediente}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9a-zA-Z]/g, ''); }}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined">
                        <TextField
                            name="year"
                            label="Año"
                            variant="outlined"
                            fullWidth
                            value={datosValidacion?.year || ''}
                            onChange={handleChangeFieldsValidation}
                            error={!!errorMessages.year}
                            helperText={errorMessages.year}
                            onInput={(e) => { e.target.value = e.target.value.replace(/[^0-9]/g, ''); }}
                            inputProps={{ maxLength: 4 }}
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>

                    <Autocomplete
                        options={juzgados.sort((a, b) => a.materia.localeCompare(b.materia))}
                        getOptionLabel={(option) => option.nombre || ''}
                        groupBy={(option) => option.materia}
                        value={datosValidacion?.juzgado || null}
                        onChange={(e, value) => handleChangeFieldsValidation({
                            target: {
                                name: "juzgado",
                                value: value
                            }
                        })}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Juzgado"
                                name="juzgado"
                                variant="outlined"
                                error={!!errorMessages.juzgado}
                                helperText={errorMessages.juzgado}
                            />
                        )}
                    />

                </Grid>
            </Grid>

            <Grid container spacing={1} sx={{ marginTop: 2, marginBottom: 6 }} >
                <Grid item xs={12}>
                    <Button
                        className='button-validation'
                        variant="contained"
                        color="primary"
                        onClick={handleValidate}
                    >
                        Validar
                    </Button>
                </Grid>
            </Grid>

            {Object.keys(carpeta).length > 0 && (<>
                <Grid container spacing={1} sx={{ marginTop: 2 }}>
                    <Grid item xs={12} sm={6} md={2}>
                        <Typography>Actor: </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={10}>
                        <Typography style={{ color: "gray" }}>
                            {carpeta.actor}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={2}>
                        <Typography>Demandado: </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={10}>
                        <Typography style={{ color: "gray" }}>
                            {carpeta.demandado || ""}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <Alert severity={carpeta.estadoJuzgado === 'ACTIVE' ? "success" : "warning"}>
                            <AlertTitle>Estatus del juzgado</AlertTitle>
                            {carpeta.estadoJuzgado === 'ACTIVE' ? datosValidacion.juzgado.nombre + " activo" : datosValidacion.juzgado.nombre + " inactivo"}
                        </Alert>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                        <Alert severity={carpeta.estadoCarpeta !== 'Archivo judicial' ? "success" : "warning"}>
                            <AlertTitle>Estatus del expediente</AlertTitle>
                            {carpeta.estadoCarpeta}
                        </Alert>
                    </Grid>

                </Grid>

                <Grid container spacing={2} sx={{ marginTop: 2 }}>
                    <Grid item xs={12}>
                        {/* Botón Reactivar */}
                        {carpeta.estadoJuzgado === "ACTIVE" && carpeta.estadoCarpeta === "Archivo judicial" && (
                            <Button
                                className="button-validation"
                                variant="contained"
                                color="primary"
                                name="reactivar"
                                onClick={handleConfirmReasignarExpediente}
                                sx={{ marginRight: 1 }}
                            >
                                Reactivar
                            </Button>
                        )}

                        {/* Botón Reasignar */}
                        {carpeta.estadoJuzgado === "INACTIVE" && (
                            <Button
                                className="button-validation"
                                variant="contained"
                                color="primary"
                                name="reasignar"
                                onClick={handleConfirmReasignarExpediente}
                            >
                                Reasignar
                            </Button>
                        )}

                       
                    </Grid>
                </Grid>

            </>)}


        </Grid>

        <ConfirmationModal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onConfirm={ 
                accion === "reasignar" ? handleReasignarExpediente
                                       : handleReactivacionExpediente
            }
            title={
                accion === "reasignar" ? "Reasignación de expediente " + datosValidacion.expediente
                                       : "Reactivación de expediente " + datosValidacion.expediente }
            message={
                accion === "reasignar" ? "¿Realmente desea reasignar este expediente?"
                                       : "¿Realmente desea reactivar este expediente?"
            }
            confirmationButton={
                accion === "reasignar" ? "Sí, reasignar" : "Sí, reactivar"
            }
            closeButton="No, cancelar"
        />
    </>
}

export default ReasignacionExpediente;