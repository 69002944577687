import React from 'react';
import { Grid, TextField } from '@mui/material';
import { onlyText, allowSpecialCharacters } from '../../../../components/Caracteres/caracteresEspeciales';

const Actor = ({
  actorTab,
  actorFisica,
  actorMoral,
  handleInputChange,
  setActorFisica,
  setActorMoral,
  errors,
  isEditable,
  materia
}) => {

  const handleFilteredInputChange = (event, setActorState, fieldName, isPersonaMoral=false) => {

    const { value } = event.target;    
    const filteredValue = isPersonaMoral ? allowSpecialCharacters(value) : onlyText(value); 
    handleInputChange({ target: { name: fieldName, value: filteredValue } }, setActorState, fieldName);
  };

  return (
    <Grid container spacing={2} mt={1}>
      <Grid sm={1}></Grid>
      <Grid ml={7} item xs={10}>
        {actorTab === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Nombre(s)"
                name="nombreActor"
                fullWidth
                value={actorFisica.nombreActor}    
                onChange={(e) => handleFilteredInputChange(e, setActorFisica, 'nombreActor')}
                error={!!errors.nombreActor}
                helperText={errors.nombreActor}
                disabled={isEditable}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Apellido paterno"
                name="apellidoPaternoActor"
                fullWidth
                value={actorFisica.apellidoPaternoActor}
                onChange={(e) => handleFilteredInputChange(e, setActorFisica, 'apellidoPaternoActor')}
                error={!!errors.apellidoPaternoActor}
                helperText={errors.apellidoPaternoActor}
                disabled={isEditable}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                label="Apellido materno"
                name="apellidoMaternoActor"
                fullWidth
                value={actorFisica.apellidoMaternoActor}
                onChange={(e) => handleFilteredInputChange(e, setActorFisica, 'apellidoMaternoActor')}
                disabled={isEditable}
              />
            </Grid>

          </Grid>
        )}

        {actorTab === 1 && (
          <Grid item xs={12}>
            <TextField
              label="Nombre"
              name="nombreActorMoral"
              fullWidth
              value={actorMoral.nombreActorMoral}
              onChange={(e) => handleFilteredInputChange(e, setActorMoral, 'nombreActorMoral', true)}
              error={!!errors.nombreActorMoral}
              helperText={errors.nombreActorMoral}
              disabled={isEditable}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Actor;
