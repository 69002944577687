import React, { useState, useEffect, useContext } from 'react';
import { Modal as MuiModal, Box, Typography, Button, Select, MenuItem, IconButton, Divider, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { fetchMotivosDevolucion, createMotivoDevolucion } from '../../pages/Bandeja/Recepcion/BandejaService';
import keycloakConf from "../../api/keycloakConf";
import { hasRole } from '../../shared/Util';
import { useToastAlertValidation } from '../Alert/ToastAlertValidation/ToastAlertValidation';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'white',
  borderRadius: '8px',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
  p: 2,
  maxWidth: '700px',
  width: '100%',
};

const ReturnTurnadoModal = ({ isOpen, handleClose, expediente, handleSave, handleRefresh }) => {
  const [motivoPersonalizado, setMotivoPersonalizado] = useState(''); 
  const [motivo, setMotivo] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [motivos, setMotivos] = useState([]);
  const { getToken } = useContext(keycloakConf);
  const { showSnackbar } = useToastAlertValidation();


  const documentoId = expediente?.documentoId;
  const expedienteNombre = expediente?.expediente || 'N/A'; 
  const isInterno = expediente?.isInterno; 

  console.log(expediente);
  
  useEffect(() => {
    if (isOpen) {
      const obtenerMotivos = async () => {
        try {
          const token = getToken();
          const motivos = await fetchMotivosDevolucion(token);
          setMotivos(motivos);
        } catch (error) {
          console.error('Error al obtener los motivos:', error);
        }
      };

      obtenerMotivos();
    }
  }, [isOpen]);

  const handleSaveSuccess = () => {
    showSnackbar(`Expediente ${expedienteNombre} devuelto correctamente`, 'success', '¡Excelente!');
    handleClose();
  };

  const handleConfirm = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const token = getToken();
      const motivoFinal = motivo === 'Otro' ? motivoPersonalizado : motivo;
      const devueltoOficialia = hasRole("OFICIAL_MAYOR") && isInterno == false;
      await createMotivoDevolucion(token, motivoFinal, documentoId, devueltoOficialia);
      handleSave(motivoFinal);
      handleSaveSuccess(); 
      handleRefresh();
    } catch (error) {
      console.error('Error al crear el motivo de devolución:', error);
      setError('Hubo un error al guardar el motivo de devolución. Por favor, intente nuevamente.');
      setMotivo('');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <MuiModal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography id="modal-title" variant="h6" component="h2" sx={{ fontWeight: 'bold', color: '#333' }}>
            { hasRole("OFICIAL_MAYOR") && isInterno == false ? 'Devolver a oficialia'  : 'Devolver turnado' } 
          </Typography>
          <IconButton onClick={() => {
              handleClose();
              setMotivo('');
            }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider sx={{ my: 1 }} />

        <Typography sx={{ mt: 2 }}>
          Motivo por el cual se devuelve el expediente {expedienteNombre}:
        </Typography>

        <Select
          value={motivo}
          onChange={(e) => setMotivo(e.target.value)}
          fullWidth
          sx={{ mt: 2 }}
        >
          {motivos.map(motivo => (
            <MenuItem key={motivo.id} value={motivo.nombre}>
              {motivo.nombre}
            </MenuItem>
          ))}
        </Select>
          
        {motivo === 'Otro' && (
          <TextField
            label="Especificar otro motivo"
            value={motivoPersonalizado}
            onChange={(e) => setMotivoPersonalizado(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
        )}

        {error && <Typography color="error">{error}</Typography>}

        <Box display="flex" justifyContent="flex-end" sx={{ mt: 4 }}>
        <Button
            variant="outlined"
            className="button-close"
            onClick={() => {
              handleClose();
              setMotivo('');
            }}
            sx={{ mr: 2 }} 
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            className="button-save"
            onClick={handleConfirm} 
            disabled={isLoading || !motivo}
            sx={{ mr: 2 }} 
          >
            {isLoading ? 'Devolviendo...' : 'Devolver'}
          </Button>
        </Box>
      </Box>
    </MuiModal>
  );
};

export default ReturnTurnadoModal;
