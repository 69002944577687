import { Box, Grid } from "@mui/material"
import { useContext, useEffect, useState } from "react";
import { fetchGetBandejaSalida, fetchGetMensajeros } from "./BandejaEnviosService";
import keycloakConf from "../../api/keycloakConf";
import RefreshButton from "../../components/Button/RefreshButton";
import { useToastAlert } from "../../components/Alert/ToastAlertSave/ToastAlertSave";
import TableBandejaEnvios from "./Grid/TableBandejaEnvios";
import Breadcrumbs from "../../components/Breadcrumb/breadcrumbs";
import SearchInput from "../../components/Search/SearchInput";

const BandejaEnvios = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [mensajeros, setMensajeros] = useState([]);
    const [bandejaEnvios, setBandejaEnvios] = useState([]);
    const { getToken } = useContext(keycloakConf);
    const [pageable, setPageable] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const { showSnackbar } = useToastAlert();

    useEffect(() => {
        loadMensajeros();
        loadBandejaEnvios();
    }, []);


    const loadMensajeros = async () => {
        const token = getToken();
        const response = await fetchGetMensajeros(token);
        setMensajeros(response);
    }

    const loadBandejaEnvios = async () => {

        try {
            const token = getToken();
            const response = await fetchGetBandejaSalida(
                token,
                page,
                rowsPerPage,
                "",
                searchQuery
            );
            setBandejaEnvios(response.content);
            setPageable(response.page);

            if (response.content.length === 0 && searchQuery !== "") {
                showSnackbar('Oficio no encontrado', 'error');
                return
            }
            if (searchQuery !== "") {
                showSnackbar("Oficio encontrado", "success")
            }

        } catch (error) {
            showSnackbar("Ha ocurrido un error al listar los oficios.", "error")
        }
    }

    return <>

        <Grid container spacing={2} alignItems="center">
            <Grid item xs={12} sm={12} md={9}>
                <Breadcrumbs istitulo={true} ></Breadcrumbs>
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
                <SearchInput
                    placeholder="Buscar folio"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    ariaLabel={"Campo de búsqueda para bandeja de envíos"}
                    tooltipTitle={"Escriba para buscar"}
                    onKeyDown={(e) => {
                        if (e.key === "Enter") {
                            loadBandejaEnvios()
                        }
                    }

                    }
                ></SearchInput>
            </Grid>
        </Grid>

        <Grid container>

            <Box mb={2} display="flex" alignItems="center">
                <Box display="flex" alignItems="center" sx={{ marginLeft: 1 }}>
                    <RefreshButton onClick={loadBandejaEnvios} />
                </Box>
            </Box>

            <TableBandejaEnvios
                pageable={pageable}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                bandejaEnvios={bandejaEnvios}
                loadBandejaEnvios={loadBandejaEnvios}
                mensajeros={mensajeros}
            />

        </Grid>
    </>
}

export default BandejaEnvios;