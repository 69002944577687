import { useContext, useEffect, useState } from "react";
import Acciones from "../../../../components/Acciones/Acciones";
import { Grid, Tab, Tabs, Box } from "@mui/material";
import Glosario from "../../../../assets/Glosario";
import General from "./tabs/General";
import Participantes from "./tabs/Participantes";
import { useNavigate } from "react-router-dom";
import { validateFields } from '../../../../components/Alert/ValidationRequired/validationRequired';
import { useToastAlert } from "../../../../components/Alert/ToastAlertSave/ToastAlertSave";
import { useToastAlertValidation } from "../../../../components/Alert/ToastAlertValidation/ToastAlertValidation";
import {  crearDemandaPenal, getSello, getTipoAudiencias, getTipoParteByTipoJuicio } from "../../DemandaService";
import keycloakConf from "../../../../api/keycloakConf";
import { onlyNames, onlyNumbers } from "../../../../components/Caracteres/caracteresEspeciales";
export default function FormDemandaPenal({
  onSave,
  juicioId,
  nombreJuicio, 
  materia,
  juicioPadreId,
  setCerrarFuncion,
  materiaId
}) {
  const { getToken } = useContext(keycloakConf);
  const token = getToken();
  const { showSnackbar, showConfirmDialog } = useToastAlert();
  const { showSnackbar: showAlertValidate } = useToastAlertValidation();

  
  const [tabvalue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const [tipoParticipantes, setTipoParticipantes] = useState([]);
  const [rolesParticipantes, setRolesParticipantes] = useState([]);
  const [tiposAudiencias, setTiposAudiencias] = useState([]);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    general: {
      nombrePromovente: "",
      apellidoPaternoPromovente: "",
      apellidoMaternoPromovente: "",
      tipoJuzgado: "",
      numOficio: "",
      numCarpetaInv: "",
      lugarHecho: "",
      fechaHecho: "",
      fechaPresentacion: "",
      horaFormal: "",
      horaMaterial: "",
      lugarDisposicion: "",
      tipoSolAudiencia: "",
      tipoAudiencia: "",
      juez: "",
      anexos: []
    },
    participantes: {}
  });

  useEffect(() => {
    obtenerParticipantes();
    obtenerTipoAudiencias();
    
  }, []);

  useEffect(() => {
    onSave(() => handleValidateFields );
    setCerrarFuncion(() => handleClose);
  }, [formData])


  const fieldsMap = {
    "general.nombrePromovente": onlyNames,
    "general.apellidoPaternoPromovente": onlyNames,
    "general.apellidoMaternoPromovente": onlyNames,
    "general.numOficio": onlyNumbers,
  };

  const obtenerParticipantes = async() => {
    
    const response = await getTipoParteByTipoJuicio(token, juicioPadreId);
    const participantes = response.filter(item => !item.nombre.includes("-"))
    const roles = response.filter(participante => participante.nombre.includes("-"));
    
    const participantesState = {}; 
    participantes.forEach(participante => {
      participantesState[participante.nombre] = [];
    });
   
    setFormData(prevState => ({
      ...prevState,
        ...prevState.general,
        participantes: participantesState
    }));
    
    setRolesParticipantes(roles);
    setTipoParticipantes(participantes)
  }



  const obtenerTipoAudiencias = async () => {
    const response = await getTipoAudiencias(token, materiaId);
    setTiposAudiencias(response);
  }


  const handleValidateFields = () => {
    const tabs = {
      "general": 0,
      "participantes": 1
    }

    const rulesFields = {
      "general.nombrePromovente": { required: true },
      "general.apellidoPaternoPromovente": { required: true },
      "general.tipoJuzgado": { required: true },
      "general.numOficio": { required: true },
      "general.numCarpetaInv": { required: true },
      "general.lugarHecho": { required: true },
      "general.fechaHecho": { required: true },
      "general.fechaPresentacion": { required: materia === "Penal" },
      "general.horaFormal": { required: nombreJuicio === "Expedientillo de Control Judicial" || nombreJuicio === "Expedientillo de Control De Actos De Investigación" },
      "general.horaMaterial": { required: nombreJuicio === "Expedientillo de Control Judicial" || nombreJuicio === "Expedientillo de Control De Actos De Investigación" },
      "general.lugarDisposicion": { required: nombreJuicio === "Expedientillo de Control Judicial" || nombreJuicio === "Expedientillo de Control De Actos De Investigación" },
      "general.tipoSolAudiencia": { required: nombreJuicio === "Causas Penales por Orden De Aprehensión" },
      "general.juez": { required: formData.general.tipoJuzgado === "2" },
      "general.tipoAudiencia": { required: true }
    }
 
    
    let errores = validateFields(formData, rulesFields, showAlertValidate);
    
    if (Object.keys(errores).length > 0) {
      let inputName = Object.keys(errores)[0];
      let [tab, campo] = inputName.split(".");
      setErrors(errores);
      handleTabChange(this, tabs[tab]);

      return false;
    }

    //Evaluación de errores en el formData:
    const participantesValidos = validarParticipantes();
    const anexosValidados = verificarAnexos();
    if(participantesValidos && !anexosValidados){
      handleSave();
    }

    setErrors({});
  }

  function validarParticipantes() {
    
    const mensajes = {
      "Victimas": "No es posible registrar la demanda sin victimas.",
      "Imputados": "No es posible registrar la demanda sin imputados.",
      "Ministerio Publico": "No es posible registrar la demanda sin ministerio."
    };
    const tiposParticipantes = ["Victimas", "Imputados", "Ministerio Publico"];
    for (const tipo of tiposParticipantes) {
      if (formData.participantes[tipo].length === 0 || formData.participantes[tipo] === 0) {
        showAlertValidate(mensajes[tipo], "error", "error");
        handleTabChange(this, 1);
        return false;
      }
    }
    return true;
  }

  const handleSave =  async() => {
    // Acción para guardar
    const data = {
      nombreProvente: formData.general.nombrePromovente,
      apellidoPaternoProvente: formData.general.apellidoPaternoPromovente,
      apellidoMaternoProvente: formData.general.apellidoMaternoPromovente,
      tipoJuzgado: formData.general.tipoJuzgado,
      tipoJuicio: juicioId,
      tipoJuicioPadre: juicioPadreId,
      numOficio: parseInt(formData.general.numOficio),
      numCarpetaInv:formData.general.numCarpetaInv,
      lugarHecho: formData.general.lugarHecho,
      fechaHecho: formData.general.fechaHecho,
      fechaPresentacion: formData.general.fechaPresentacion,
      horaFormal: formData.general.horaFormal,
      horaMaterial: formData.general.horaMaterial,
      lugarDisposicion: formData.general.lugarDisposicion,
      tipoSolAudiencia: formData.general.tipoSolAudiencia,
      tipoAudiencia: formData.general.tipoAudiencia,
      juezId: formData.general.juez !== "" ? formData.general.juez : null,
      anexos:formData.general.anexos,
      victimas: mapearEstructuraParticipante(formData.participantes["Victimas"]),
      imputados:mapearEstructuraParticipante(formData.participantes["Imputados"]),
      ministerio:mapearEstructuraParticipante(formData.participantes["Ministerio Publico"]),
      terceroInvolucrado:mapearEstructuraParticipante(formData.participantes["Tercero involucrado"]),
    }
    console.log(data);
    
    const response = await crearDemandaPenal(token, data);

    if(response.id){
      showSnackbar("Demanda generada con éxito", "success");
      navigate("/api/workflow/demanda");
      try {
            const sello = await getSello(token, response.id);
            let blob = new Blob([sello], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.target = "sello.pdf";
            link.click();
          } catch (error) {
            console.error("Error al generar el sello:", error);
            showSnackbar("¡Algo salió mal al obtener el sello!", "error");
          }
    }else{
      showAlertValidate("Ha ocurrido un error al generar la demanda.", "error", "error");
    }
    
  };

  const verificarAnexos = () => {
    const errores = formData.general.anexos.some(element =>  element.length < 3  );
    if(errores){
      showAlertValidate("Anexos incorrectos, favor de validarlos.", "error", "error");
      handleTabChange(null, 0);
    }

    
    return errores
  }

  const mapearEstructuraParticipante = (participantes) => {
    if (!Array.isArray(participantes) || participantes.length === 0) {  return []; }

    return participantes.map(({ nombre, apellidoPaterno, apellidoMaterno, pseudonimo, tipoPersona, categoria }) => {
      let tipoParte;
      switch (categoria) {
        case "Victimas":
          tipoParte = 4;
          break;
        case "Imputados":
          tipoParte = 5;
          break;
        case "Ministerio Publico":
          tipoParte = 6;
          break;
        case "Tercero Involucrado":
          tipoParte = 7;
          break;
        default:
          tipoParte = "";
      }
  
      return {
        nombre,
        apellidoPaterno,
        apellidoMaterno,
        pseudonimo,
        tipoPersona,
        tipoParte,
        curp: "",
        ine: "",
        domicilio: "",
        celular: "",
        correoElectronico: ""
      };
    });
  }
  
  

  const handleClose = () => {
    // Acción para cerrar
    navigate('/api/workflow/demanda');
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    
    const caracterValue = (fieldsMap[name] || ((val) => val))(value);

    setFormData((prevState) => {
      // Clona el estado actual
      const updatedState = { ...prevState };
      let current = updatedState;

      // Divide el name en claves y recorre el objeto hasta la penúltima clave
      const keys = name.split('.');
      keys.slice(0, -1).forEach((key) => {
        current[key] = { ...current[key] };
        current = current[key];
      });

      // Actualiza el valor del último nivel
      current[keys[keys.length - 1]] = caracterValue;

      return updatedState;
    });
  };


  return (
    <>
      { /* <Acciones accion1={handleValidateFields} accion2={handleClose}  />  */ }

      <Box className="box-sombra" sx={{ padding: 4 }}>
        <h3 className="title1">{Glosario.oralidadFamiliar}</h3>
        <form>
          <Grid container spacing={2} sx={{ overflowY: "auto" }}>
            {/* Tabs */}
            <Grid item xs={12}>
              <Tabs
                value={tabvalue}
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                sx={{ borderBottom: 1, borderColor: "divider" }}
              >
                <Tab label="General" />
                <Tab label="Participantes" />
              </Tabs>
            </Grid>

            {/* Contenido de los tabs */}
            <Grid item xs={12}>
              {tabvalue === 0 && 
                <General 
                  formData={formData}
                  handleInputChange={handleInputChange} 
                  setFormData={setFormData}
                  nombreJuicio={nombreJuicio}
                  errors={errors}
                  tiposAudiencias={tiposAudiencias} 
                  materia={materia}
                  materiaId={materiaId} />}

              {tabvalue === 1 && 
                <Participantes 
                  formData={formData.participantes}
                  setFormdata={setFormData}
                  errors={errors}
                  tipoParticipantes={tipoParticipantes}
                  rolesParticipantes={rolesParticipantes} />}
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
