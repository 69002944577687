
import axios from "../../api/axiosConfig";


const urlPruebaGeneradorQR = process.env.REACT_APP_TRIALS_API_URL +  '/api/workflow/generarQR/';
const urlQrPorCasilla = process.env.REACT_APP_TRIALS_API_URL +  '/api/workflow/generarQR/porCasilla/';

export const getQRPrueba = async (token, expMin, expMax, year) => {
    try {
      const response = await axios.get(urlPruebaGeneradorQR + `${expMin}/${expMax}/${year}`, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error al obtener el reporte:', error);
      throw error;
    }
  };

  export const getQrPorCasilla = async (token, expediente, year, casilla) => {
    try {
      const response = await axios.get(urlQrPorCasilla + `${expediente}/${year}/${casilla}`, {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      return response.data;
    } catch (error) {
      console.error('Error al obtener el reporte:', error);
      throw error;
    }
  };